import React from 'react';
import { Button, Container, Link } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
    notFound: {
        display: "flex",
        flex: "1 1 auto",
        justifyContent: "center"
    },
}))

const NotFound = () => {
    const classes = useStyles();

    return (
        <Container className={classes.notFound}>
            <div style={{textAlign: "center"}}>
                <div className="notfound-404">
                    <h1>404</h1>
                </div>
                <h2>¡Lo sentimos, la página no fue encontrada!</h2>
                <p>Es posible que la página que está buscando se haya eliminado, cambió de nombre de url o no está
                    disponible temporalmente.</p>
                <Link href="/">
                    <Button color="primary">
                        Volver al Inicio
                    </Button>
                </Link>
            </div>
        </Container>)
};

export default (NotFound);
