import React from 'react'
import { useGlobalSpinnerContext } from '../../context/GlobalSpinnerContext'
import makeStyles from '@mui/styles/makeStyles';
import { Backdrop, CircularProgress } from "@mui/material";

const useStyles = makeStyles((theme) => ({
    root: {
        // position: 'absolute',
        // zIndex: 99999,
        position: 'fixed',
        zIndex: theme.zIndex.drawer + 1,
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(0, 0, 0, 0.3)',
        fontSize: '30px',
        color: 'white',
        fontWeight: 'bold',
        display: 'flex',
        justifyContent: 'center'
    }
}));

const GlobalSpinner = props => {
    const classes = useStyles();
    const isGlobalSpinnerOn = useGlobalSpinnerContext()
    return isGlobalSpinnerOn ? (
        <Backdrop className={classes.root} open={true}>
            <CircularProgress color="inherit" />
        </Backdrop>
    ) : null
}

export default GlobalSpinner;
