import React from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import withStyles from '@mui/styles/withStyles';
import { Typography } from "@mui/material";

const styles = theme => ({
  toolbarRoot: {
    paddingRight: 5,
  },
  bold: {
    color: "black",
    fontWeight: 600
  }
});

const Header = props => {
  const { classes } = props;
  return (
    <AppBar position="fixed" style={{background: "white", zIndex: 1201}} enableColorOnDark>
      <Toolbar disableGutters classes={{ root: classes.toolbarRoot }}>
        <Typography variant="h5" color="primary" style={{marginLeft: 20}}>Visor de Escenarios de Cambio climático, Vulnerabilidad y Adaptación Paraguay</Typography>
        <div style={{flexGrow: 1}}/>
        <img src={'/mades_2023.jpg'} alt="Logo" style={{height: "64px"}} />
        <img src={'/dinac.png'} alt="Logo" style={{height: "60px"}} />
        <img src={'/dmh.jpg'} alt="Logo" style={{height: "60px"}} />
        {/*<span><Typography className={classes.bold}>Con el apoyo de: </Typography></span>*/}
        {/*<img src={'/fman.png'} alt="Logo" style={{height: "60px"}} />*/}
        {/*<img src={'/PNUD.png'} alt="Logo" style={{height: "60px"}} />*/}
      </Toolbar>
    </AppBar>
  );
};

export default withStyles(styles)(Header);
