import React, { Fragment, useEffect, useRef } from 'react';
import { MapContainer, LayersControl, TileLayer, ZoomControl, GeoJSON } from "react-leaflet";
import Header from "../components/Header/Header";
import Sidebar from "../components/Sidebar/Sidebar";
import makeStyles from '@mui/styles/makeStyles';
import { control, DomUtil } from "leaflet";
import { SimpleMapScreenshoter } from 'leaflet-simple-map-screenshoter';
import chroma from 'chroma-js';
import FileSaver from 'file-saver';
import { useGlobalSpinnerActionsContext } from "../context/GlobalSpinnerContext";

const drawerWidth = 120;

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
    },
    content: {
        flexGrow: 1,
        marginLeft: theme.spacing(9),
        padding: theme.spacing(3),
        top: theme.spacing(7),
        width: `calc(100% - ${drawerWidth}px)`,
        position: "fixed",
        overflowX: "hidden",
        height: `calc(100vh - 104px)`
    },
    contentShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(["width", "margin"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen
        })
    },

}));

const colors = [
    '#a50026',
    '#d73027',
    '#f46d43',
    '#fdae61',
    '#fee08b',
    '#d9ef8b',
    '#a6d96a',
    '#66bd63',
    '#1a9850',
    '#006837'];

const indiceND = [
    0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100
];

const palette = chroma.scale(colors).domain(indiceND);

function getColor(d) {
    return palette(d).hex();
}

const downloadJson = async ({data}) => {
    const fileName = "file";
    const json = JSON.stringify(data);
    const blob = new Blob([json],{type:'application/json'});
    const href = await URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = href;
    link.download = fileName + ".geojson";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
}

var downloadFile;
var logosApoyo;

function MapDownload({data, map}) {
    const params = data || 0;

    useEffect(() => {
        if (map) {
            downloadFile = control({ position: "topright" });
            downloadFile.onAdd = () => {
                const helpDiv = DomUtil.create("div", 'leaflet-control-simpleMapScreenshoter');
                helpDiv.innerHTML = '<button title="Descargar GeoJSON" ' +
                    'style="width: 30px; height: 30px; border: none; background: white">' +
                    '<i class="fa fa-download"></i>' +
                    '</button>';

                helpDiv.addEventListener("click", () => {
                    downloadJson({data: params});
                });
                return helpDiv;
            }
            map.addControl(downloadFile);

            return () => {
                map.removeControl(downloadFile);
            };
        }

    }, [map, params]);
    return null;
}

function Legend({ map }) {
    useEffect(() => {
        if (map) {
            const legend = control({ position: "bottomright" });
            legend.onAdd = () => {
                const div = DomUtil.create("div", "info legend");
                let title = 'ND-GAIN';
                let labels = [];
                let from;
                let originData = indiceND;

                labels.push(
                    '<i style="background:' + getColor(originData[originData.length - 1]) + '"></i> ' +
                    '100 - Más Resiliente');
                for (let i = originData.length - 1; i > 1; i=i-1) {
                    from = originData[i - 1];

                    labels.push(
                        '<i style="background:' + getColor(from) + '"></i> ' +
                        from
                    );
                }
                labels.push(
                    '<i style="background:' + getColor(originData[0]) + '"></i> ' +
                    '0 - Más Vulnerable');

                div.innerHTML =
                    `<h4>${title}</h4>`
                div.innerHTML += labels.join("<br>");
                return div;
            };

            logosApoyo = control({ position: "bottomright" });
            logosApoyo.onAdd = () => {
                const helpDiv = DomUtil.create("div", 'info legend');
                helpDiv.innerHTML = '' +
                    '<span><Typography><b>Con el apoyo de: </b></Typography></span>' +
                    '<img src="GEF.png" alt="Logo" style="height: 50px" />' +
                    '<img src="PNUD.png" alt="Logo" style="height: 55px" />';

                return helpDiv;
            }
            map.addControl(logosApoyo);
            map.addControl(legend);

            return () => {
                map.removeControl(legend);
                map.removeControl(logosApoyo);
            };
        }
    }, [map]);
    return null;
}

function MapDownloadImage({map}) {

    useEffect(() => {
        if (map) {
            let pluginOptions = {
                preventDownload: false, // prevent download on button click
                domtoimageOptions: {}, // see options for dom-to-image
                position: 'topright', // position of take screen icon
                screenName: 'Mapa - Escenarios Climáticos', // string or function
                hideElementsWithSelectors: ['.leaflet-control-container'], // by default hide map controls All els must be child of _map._container
                mimeType: 'image/png', // used if format == image,
                caption: null, // string or function, added caption to bottom of screen
                captionFontSize: 15,
                captionFont: 'Arial',
                captionColor: 'black',
                captionBgColor: 'white',
                captionOffset: 5,
                // callback for manually edit map if have warn: "May be map size very big on that zoom level, we have error"
                // and screenshot not created
                onPixelDataFail: async function({ node, plugin, error, mapPane, domtoimageOptions }) {
                    // Solutions:
                    // decrease size of map
                    // or decrease zoom level
                    // or remove elements with big distanses
                    // and after that return image in Promise - plugin._getPixelDataOfNormalMap
                    return plugin._getPixelDataOfNormalMap(domtoimageOptions)
                }
            }

            new SimpleMapScreenshoter(pluginOptions).addTo(map);

            return () => { };
        }

    }, [map]);
    return null;
}

var layerDownload;
function LayerDownload({map, layer}) {

    useEffect(() => {
        if (map) {
            layerDownload = control({ position: "topright" });
            layerDownload.onAdd = () => {
                const helpDiv = DomUtil.create("div", 'leaflet-control-simpleMapScreenshoter');
                helpDiv.innerHTML = '<button title="Descargar GeoJSON de Capa Seleccionada" ' +
                    'style="width: 30px; height: 30px; border: none; background: white">' +
                    '<i class="fa fa-download"></i>' +
                    '</button>';

                if (layer) {
                    helpDiv.addEventListener("click", () => {
                        let filename = [...layerMap.entries()]
                            .filter(({ 1: v }) => v === layer)
                            .map(([k]) => k);
                        FileSaver.saveAs(
                            process.env.PUBLIC_URL + `reports/${filename}.pdf`, `${layer}.pdf`);
                        console.log(layer);
                    });
                }
                return helpDiv;
            }
            map.addControl(layerDownload);

            return () => {
                map.removeControl(layerDownload);
            };
        }
    }, [map, layer]);
    return null;
}

// get table row data



const layerMap = new Map([
    ["ciudades_resilientes", "Ciudades Resilientes"],
    ["ecosistemas_biodiversidad", "Ecosistemas y Biodiversidad"],
    ["energia", "Energía"],
    ["prod_agro_forestal", "Producción Agropecuaria Forestal y Seguridad Alimentaria"],
    ["recursos_hidricos", "Recursos Hídricos"],
    ["salud_epidemiologia", "Salud y Epidemiología"],
    ["transporte", "Transporte"]
])

const Vulnerabilidades = props => {
    const classes = useStyles();

    const setGlobalSpinner = useGlobalSpinnerActionsContext();

    const [map, setMap] = React.useState(null);
    const [layerActive, setLayerActive] = React.useState(null);
    const [showInfo, setShowInfo] = React.useState(false);
    const [dataLayer, setDataLayer] = React.useState(null);
    const [ciudadesResilientes, setCiudadesResilientes] = React.useState(null);
    const [ecosistemas, setEcosistemas] = React.useState(null);
    const [energia, setEnergia] = React.useState(null);
    const [prodAgroForestal, setProdAgroForestal] = React.useState(null);
    const [recursosHidricos, setRecursosHidricos] = React.useState(null);
    const [saludEpidemiologia, setSaludEpidemiologia] = React.useState(null);
    const [transporte, setTransporte] = React.useState(null);
    const ciudadesResilientesRef = useRef(null);
    const ecosistemasRef = useRef(null);
    const energiaRef = useRef(null);
    const prodAgroForestalRef = useRef(null);
    const recursosHidricosRef = useRef(null);
    const saludEpidemiologiaRef = useRef(null);
    const transporteRef = useRef(null);

    function style(feature) {
        let valor = feature.properties.ND_GAIN ? feature.properties.ND_GAIN.toFixed(2): feature.properties.ND_INDEX.toFixed(2);

        return {
            fillColor: getColor(valor),
            weight: 2,
            opacity: 1,
            color: 'black',
            stroke: 1,
            fillOpacity: 1
        };
    }

    const tdData = () => {
        if (dataLayer && dataLayer.features && dataLayer.features.length > 0) {
            dataLayer.features.sort(GetSortOrder('ND_INDEX'));
                return dataLayer.features
                    .map((data)=> {
                        return(
                            `<tr>
                                <td>${data.properties.DPTO_DESC}</td>
                                <td>${data.properties.ND_INDEX.toFixed(2)}</td>
                            </tr>`
                        )
                    })
                    .sort((data) => GetSortOrder('properties.ND_INDEX'))
        } else {
            return (``);
        }
    }

    function GetSortOrder(prop) {
        return function(a, b) {
            if (a.properties[prop] > b.properties[prop]) {
                return -1;
            } else if (a.properties[prop] < b.properties[prop]) {
                return 1;
            }
            return 0;
        }
    }

    const ShowLayerNDGAIN = ({ map, layer }) => {
        useEffect(() => {
            if (map) {
                const ndGainLayer = control({ position: "topright" });
                ndGainLayer.onAdd = () => {
                    const ndGainDiv = DomUtil.create("div", "leaflet-control-simpleMapScreenshoter");
                    ndGainDiv.innerHTML = '<button title="Mostrar/Ocultar Información de Capa Seleccionada" ' +
                        'style="width: 30px; height: 30px; border: none; background: white">' +
                        '<i class="fa fa-info"></i>' +
                        '</button>';
                    ndGainDiv.addEventListener('click', () => {
                        if (layerActive) {
                            setShowInfo(!showInfo);
                        }
                    });
                    return ndGainDiv;
                };
                map.addControl(ndGainLayer);

                return () => {
                    map.removeControl(ndGainLayer);
                };
            }
        }, [map, layer]);
    return null;
};

    const InfoLayer = ({ map, layer }) => {
        useEffect(() => {
            if (map && layer) {
                if (!showInfo) {
                    return;
                }
                const infoLayer = control({ position: "bottomleft" });
                infoLayer.onAdd = () => {
                    const div = DomUtil.create("div", "info legend");
                    let dataTable = `
                        <style type="text/css">
                        .tg {border - collapse:collapse;border-color:#ccc;border-spacing:0;}
                        .tg td{background - color:#fff;border-color:#ccc;border-style:solid;border-width:0px;color:#333;
                        font-family:Arial, sans-serif;font-size:14px;overflow:hidden;padding:10px 5px;word-break:normal;}
                        .tg th{background - color:#f0f0f0;border-color:#ccc;border-style:solid;border-width:0px;color:#333;
                        font-family:Arial, sans-serif;font-size:14px;font-weight:normal;overflow:hidden;padding:10px 5px;word-break:normal;}
                        .tg .tg-1wig{font - weight:bold;text-align:left;vertical-align:top}
                        .tg .tg-0lax{text - align:left;vertical-align:top}
                    </style>
                    <table className="tg">
                        <thead>
                            <tr>
                                <th className="tg-1wig">DEPARTAMENTO</th>
                                <th className="tg-1wig">ND-GAIN</th>
                            </tr>
                        </thead>
                        <tbody>
                            ${tdData()}
                        </tbody>
                    </table>`
                    div.innerHTML = `<h4>Sector: ${layer}</h4>`
                    div.innerHTML += dataTable;
                    return div;
                };
                map.addControl(infoLayer);

                return () => {
                    map.removeControl(infoLayer);
                };
            }
        }, [map, layer]);
        return null;
    };

    const handleCallbackV = (data) => {
        setLayerActive(data.sectorVulnerable);
        setGlobalSpinner(true);
        if (data.sectorVulnerable === layerMap.get('ciudades_resilientes')) {
            fetch(`data/ciudades_resilientes.json`)
                .then((res) => res.json())
                .then((data) => {
                    setCiudadesResilientes(data);
                    setDataLayer(data);
                    setGlobalSpinner(false);
                });
        }
        else if (data.sectorVulnerable === layerMap.get('ecosistemas_biodiversidad')) {
            fetch(`data/ecosistemas_biodiversidad.json`)
                .then((res) => res.json())
                .then((data) => {
                    setEcosistemas(data);
                    setDataLayer(data);
                    setGlobalSpinner(false);
                });
        }
        else if (data.sectorVulnerable === layerMap.get('energia')) {
            fetch(`data/energia.json`)
                .then((res) => res.json())
                .then((data) => {
                    setEnergia(data);
                    setDataLayer(data);
                    setGlobalSpinner(false);
                });
        }
        else if (data.sectorVulnerable === layerMap.get('prod_agro_forestal')) {
            fetch(`data/prod_agro_forestal.json`)
                .then((res) => res.json())
                .then((data) => {
                    setProdAgroForestal(data);
                    setDataLayer(data);
                    setGlobalSpinner(false);
                });
        }
        else if (data.sectorVulnerable === layerMap.get('recursos_hidricos')) {
            fetch(`data/recursos_hidricos.json`)
                .then((res) => res.json())
                .then((data) => {
                    setRecursosHidricos(data);
                    setDataLayer(data);
                    setGlobalSpinner(false);
                });
        }
        else if (data.sectorVulnerable === layerMap.get('salud_epidemiologia')) {
            fetch(`data/salud_epidemiologia.json`)
                .then((res) => res.json())
                .then((data) => {
                    setSaludEpidemiologia(data);
                    setDataLayer(data);
                    setGlobalSpinner(false);
                });
        }
        else if (data.sectorVulnerable === layerMap.get('transporte')) {
            fetch(`data/transporte.json`)
                .then((res) => res.json())
                .then((data) => {
                    setTransporte(data);
                    setDataLayer(data);
                    setGlobalSpinner(false);
                });
        }
    }

    function onEachFeatureCiudadesResilientes(feature, layer) {
        layer.on({
            'click': function (e) {
                if (feature.properties) {
                    let info = feature.properties;
                    layer.bindTooltip(
                        `<h4 style="color: dodgerblue"><b>Departamento: </b> ${info.DPTO_DESC}</h4>
<b>ND-GAIN: </b> ${info.ND_INDEX ? info.ND_INDEX.toFixed(2): info.ND_INDEX}<br>

    <b>Indicadores de Vulnerabilidad</b>
    <div class="container">
        <table class="table">
            <tr>
                <td>Densidad poblacional por departamento</td>
                <td>${info.V1.toFixed(2)}</td>
            </tr>
            <tr>
                <td>Nivel de Pobreza por departamento</td>
                <td>${info.V2.toFixed(2)}</td>
            </tr>
            <tr>
                <td>Porcentaje de municipios con límites ribereños</td>
                <td>${info.V3.toFixed(2)}</td>
            </tr>
            <tr>
                <td>Densidad de la población ribereña (cota 58)</td>
                <td>${info.V4.toFixed(2)}</td>
            </tr>
            <tr>
                <td>Servicios turísticos</td>
                <td>${info.V5.toFixed(2)}</td>
            </tr>
            <tr>
                <td><b>Vulnerabilidad Media</b></td>
                <td><b>${info.V_MEDIA.toFixed(2)}</b></td>
            </tr>
        </table>
    </div>
    <b>Indicadores de Capacidad</b>
    <div class="container">
        <table class="table">
            <tr>
                <td>Porcentaje de municipios con planes de acción climática por departamento</td>
                <td>${info.C1}</td>
            </tr>
            <tr>
                <td>Porcentaje de municipios con planes de adaptación al CC</td>
                <td>${info.C2.toFixed(2)}</td>
            </tr>
            <tr>
                <td>Porcentaje de municipios con planes de GRRD por departamento</td>
                <td>${info.C3.toFixed(2)}</td>
            </tr>
            <tr>
                <td>Porcentaje de municipios con SAT ante inundaciones por departamento</td>
                <td>${info.C4.toFixed(2)}</td>
            </tr>
            <tr>
                <td>Porcentaje de infraestructura como defensas costeras, dragados, etc, por departamento</td>
                <td>${info.C5 ? info.C5.toFixed(2):""}</td>
            </tr>
            <tr>
                <td>Porcentaje del desarrollo turístico por departamento</td>
                <td>${info.C6.toFixed(2)}</td>
            </tr>
            <tr>
                <td>Porcentaje de municipios con planes de ordenamiento territorial por departamento</td>
                <td>${info.C7.toFixed(2)}</td>
            </tr>
            <tr>
                    <td><b>Capacidad Media</b></td>
                    <td><b>${info.C_MEDIA.toFixed(2)}</b></td>
                </tr>
        </table>
    </div>`, { permanent: false, className: 'table-own' }).openTooltip();}},
            'mouseout': function (e) {
                layer.closeTooltip();
            }})}

    function onEachFeatureEcoBio(feature, layer) {
        layer.on({
            'click': function (e) {
                if (feature.properties) {
                    let info = feature.properties;
                    layer.bindTooltip(
                        `<h4 style="color: dodgerblue"><b>Departamento: </b> ${info.DPTO_DESC}</h4>
<b>ND-GAIN: </b> ${info.ND_INDEX.toFixed(2)}<br>

    <b>Indicadores de Vulnerabilidad</b>
    <div class="container">
        <table class="table">
            <tr>
                <td>Superficie boscosa por departamento</td>
                <td>${info.V1.toFixed(2)}</td>
            </tr>
            <tr>
                <td>Áreas silvestres protegidas por departamento</td>
                <td>${info.V2.toFixed(2)}</td>
            </tr>
            <tr>
                <td><b>Vulnerabilidad Media</b></td>
                <td><b>${info.V_MEDIA.toFixed(2)}</b></td>
            </tr>
        </table>
    </div>
    <b>Indicadores de Capacidad</b>
    <div class="container">
        <table class="table">
            <tr>
                <td>Cantidad de acciones sostenibles que incluyan la participación de las mujeres por departamento</td>
                <td>${info.C1.toFixed(2)}</td>
            </tr>
            <tr>
                <td>Cantidad de acciones sostenibles que incluyan a comunidades indígenas por departamento</td>
                <td>${info.C2.toFixed(2)}</td>
            </tr>
            <tr>
                <td>Cantidad de titulaciones de áreas silvestres protegidas por departamento</td>
                <td >${info.C3.toFixed(2)}</td>
            </tr>
            <tr>
                <td>Superficie boscosa protegida por departamento</td>
                <td >${info.C4.toFixed(2)}</td>
            </tr>
            <tr>
                <td><b>Capacidad Media</b></td>
                <td><b>${info.C_MEDIA.toFixed(2)}</b></td>
            </tr>
        </table>
    </div>`, { permanent: false, className: 'table-own' }).openTooltip();}},
            'mouseout': function (e) {
                layer.closeTooltip();
            }})}

    function onEachFeatureEnergia(feature, layer) {
        layer.on({
            'click': function (e) {
                if (feature.properties) {
                    let info = feature.properties;
                    layer.bindTooltip(
                        `<h4 style="color: dodgerblue"><b>Departamento: </b> ${info.DPTO_DESC}</h4>
<b>ND-GAIN: </b> ${info.ND_INDEX.toFixed(2)}<br>
    <b>Indicadores de Vulnerabilidad</b>
    <div class="container">
        <table class="table">
            <tr>
                <td>Densidad poblacional</td>
                <td>${info.V1.toFixed(2)}</td>
            </tr>
            <tr>
                <td>Cantidad de comunidades indígenas por departamento</td>
                <td>${info.V2.toFixed(2)}</td>
            </tr>
            <tr>
                <td>Cantidad de cauces hídricos que poseen sistemas de generación de energía hidroelétrica</td>
                <td>${info.V3.toFixed(2)}</td>
            </tr>
            <tr>
                <td>Cantidad de ríos principales que cruzan por departamento</td>
                <td>${info.V4.toFixed(2)}</td>
            </tr>
            <tr>
                <td>Porcentaje de la población que utilizan biomasa como sistema de cocción</td>
                <td>${info.V5.toFixed(2)}</td>
            </tr>
            <tr>
                <td>Porcentaje de disminución de bosques protectores por departamentos</td>
                <td>${info.V6.toFixed(2)}</td>
            </tr>
            <tr>
                <td><b>Vulnerabilidad Media</b></td>
                <td><b>${info.V_MEDIA.toFixed(2)}</b></td>
            </tr>
        </table>
    </div>
    <b>Indicadores de Capacidad</b>
    <div class="container">
        <table class="table">
            <tr>
                <td>Porcentaje de la población con suministro de energía eléctrica</td>
                <td>${info.C1.toFixed(2)}</td>
            </tr>
            <tr>
                <td>Porcentaje de la población indígena con suministro de energía eléctrica</td>
                <td>${info.C2.toFixed(2)}</td>
            </tr>
            <tr>
                <td>Porcentaje de los municipios que tienen SAT ante tormentas eléctricas por departamento</td>
                <td>${info.C3.toFixed(2)}</td>
            </tr>
            <tr>
                <td>Porcentaje de acciones relacionadas a la utilización de energías renovables por departamento</td>
                <td>${info.C4.toFixed(2)}</td>
            </tr>
            <tr>
                <td><b>Capacidad Media</b></td>
                <td><b>${info.C_MEDIA.toFixed(2)}</b></td>
            </tr>
        </table>
    </div>`, { permanent: false, className: 'table-own' }).openTooltip();}},
            'mouseout': function (e) {
                layer.closeTooltip();
            }})}

    function onEachFeatureAgroForestal(feature, layer) {
        layer.on({
            'click': function (e) {
                if (feature.properties) {
                    let info = feature.properties;
                    layer.bindTooltip(
                        `<h4 style="color: dodgerblue"><b>Departamento: </b> ${info.DPTO_DESC}</h4>
<b>ND-GAIN: </b> ${info.ND_INDEX.toFixed(2)}<br>

    <b>Indicadores de Vulnerabilidad</b>
    <div class="container">
        <table class="table">
            <tr>
                <td>Índice de desarrollo humano</td>
                <td>${info.V1.toFixed(2)}</td>
            </tr>
            <tr>
                <td>Índice de calidad de empleo</td>
                <td>${info.V2.toFixed(2)}</td>
            </tr>
            <tr>
                <td>Índice de necesidades básicas insatisfechas</td>
                <td>${info.V3.toFixed(2)}</td>
            </tr>
            <tr>
                <td>Superficie cultivada por departamento</td>
                <td>${info.V4.toFixed(2)}</td>
            </tr>
            <tr>
                <td>Porcentaje de desnutrición</td>
                <td>${info.V5.toFixed(2)}</td>
            </tr>
            <tr>
                <td>Porcentaje del número de cabezas de ganado</td>
                <td>${info.V6.toFixed(2)}</td>
            </tr>
            <tr>
                <td>Frecuencia de temperaturas por debajo de 5 grados</td>
                <td>${info.V7.toFixed(2)}</td>
            </tr>
            <tr>
                <td>Frecuencia de tormentas severas</td>
                <td>${info.V8.toFixed(2)}</td>
            </tr>
            <tr>
                <td>Frecuencia de temperaturas altas extremas</td>
                <td>${info.V9.toFixed(2)}</td>
            </tr>
            <tr>
                <td>Porcentaje de productores que trabajan en agricultura familiar</td>
                <td>${info.V10.toFixed(2)}</td>
            </tr>
            <tr>
                <td>Superficie utilizada para pastura, plantaciones o bosques nativos</td>
                <td>${info.V11.toFixed(2)}</td>
            </tr>
            <tr>
                <td><b>Vulnerabilidad Media</b></td>
                <td><b>${info.V_MEDIA.toFixed(2)}</b></td>
            </tr>
        </table>
    </div>
    <b>Indicadores de Capacidad</b>
    <div class="container">
        <table class="table">
            <tr>
                <td>Superficie cultivada con sistemas de riego tecnificado</td>
                <td>${info.C1.toFixed(2)}</td>
            </tr>
            <tr>
                <td>Porcentaje de productores que realizan agricultura tecnificada</td>
                <td>${info.C2.toFixed(2)}</td>
            </tr>
            <tr>
                <td>Porcentaje de productores que utilizan sistemas de producción sostenible</td>
                <td>${info.C3.toFixed(2)}</td>
            </tr>
            <tr>
                <td>Porcentaje de capacitaciones al sector agropecuario</td>
                <td>${info.C4.toFixed(2)}</td>
            </tr>
            <tr>
                <td><b>Capacidad Media</b></td><td ><b>${info.C_MEDIA.toFixed(2)}</b></td>
            </tr>
        </table>
    </div>`, { permanent: false, className: 'table-own' }).openTooltip();}},
            'mouseout': function (e) {
                layer.closeTooltip();
            }})}

    function onEachFeatureSalud(feature, layer) {
        layer.on({
            'click': function (e) {
                if (feature.properties) {
                    let info = feature.properties;
                    layer.bindTooltip(
                        `<h4 style="color: dodgerblue"><b>Departamento: </b> ${info.DPTO_DESC}</h4>
<b>ND-GAIN: </b> ${info.ND_INDEX.toFixed(2)}<br>

    <b>Indicadores de Vulnerabilidad</b>
    <div class="container">
        <table class="table">
            <tr>
                <td>Índice de salud (Dengue y Leishmaniosis)</td>
                <td>${info.V1.toFixed(2)}</td>
            </tr>
            <tr>
                <td>Índice ambiental (cobertura vegetal y focos de calor)</td>
                <td>${info.V2.toFixed(2)}</td>
            </tr>
            <tr>
                <td>Frecuencia de temperaturas por debajo de 5 grados</td>
                <td >${info.V3.toFixed(2)}</td>
            </tr>
            <tr>
                <td>Frecuencia de tormentas severas</td>
                <td>${info.V4.toFixed(2)}</td>
            </tr>
            <tr>
                <td>Frecuencia de temperaturas altas extremas</td>
                <td>${info.V5.toFixed(2)}</td>
            </tr>
            <tr>
                <td><b>Vulnerabilidad Media</b></td>
                <td><b>${info.V_MEDIA.toFixed(2)}</b></td>
            </tr>
        </table>
    </div>
    <b>Indicadores de Capacidad</b>
    <div class="container">
        <table class="table">
            <tr>
                <td>Índice de capacidades sociales</td>
                <td>${info.C1.toFixed(2)}</td>
            </tr>
            <tr>
                <td>Puestos de salud (del MSPyBS, IPS y privados) en todas sus categorias por departamento.</td>
                <td>${info.C2.toFixed(2)}</td>
            </tr>
            <tr>
                <td>Cantidad de equipos para el sistema de vigilancia sanitaria y epidemiológica por departamento.</td>
                <td>${info.C3.toFixed(2)}</td>
            </tr>
            <tr>
                <td>Número de personas atendidas en los establecimientos de salud del MSP y BS. Año 2021</td>
                <td>${info.C4.toFixed(2)}</td>
            </tr>
            <tr>
                <td>Cantidad de universidades que cuenten con carreras relacionadas a la salud por departamento</td>
                <td>${info.C5.toFixed(2)}</td>
            </tr>
            <tr>
                <td>Cantidad de campañas de concienciación contra enfermedades transmitidas por vectores u otros por departamento.</td>
                <td>${info.C6.toFixed(2)}</td>
            </tr>
            <tr>
                <td>Cantidad de acciones de prevención contra enfermedades transmitidas por vectores u otros por departamento.</td>
                <td>${info.C7.toFixed(2)}</td>
            </tr>
            <tr>
                <td><b>Capacidad Media</b></td>
                <td><b>${info.C_MEDIA.toFixed(2)}</b></td>
            </tr>
        </table>
    </div>`, { permanent: false, className: 'table-own' }).openTooltip();}},
            'mouseout': function (e) {
                layer.closeTooltip();
            }})}

    function onEachFeatureTransporte(feature, layer) {
        layer.on({
            'click': function (e) {
                if (feature.properties) {
                    let info = feature.properties;
                    layer.bindTooltip(
                        `<h4 style="color: dodgerblue"><b>Departamento: </b> ${info.DPTO_DESC}</h4>
<b>ND-GAIN: </b> ${info.ND_INDEX.toFixed(2)}<br>

    <b>Indicadores de Vulnerabilidad</b>
    <div class="container">
        <table class="table">
            <tr>
                <td>Departamentos con zonas costeras a cauces hídricos transfronterizos.</td>
                <td>${info.V1.toFixed(2)}</td>
            </tr>
            <tr>
                <td>Recurrencia de eventos de estiages por estaciones hidrometricas en el Rio Paraguay y Paraná. (1999-2021)</td>
                <td>${info.V2.toFixed(2)}</td>
            </tr>
            <tr>
                <td><b>Vulnerabilidad Media</b></td>
                <td><b>${info.V_MEDIA.toFixed(2)}</b></td>
            </tr>
        </table>
    </div>
    <b>Indicadores de Capacidad</b>
    <div class="container">
        <table class="table">
            <tr>
                <td>Número promedio de intervenciones a cauces hídricos transfronterizos como dragados, o una tendencia de estos.</td>
                <td>${info.C1.toFixed(2)}</td>
            </tr>
            <tr>
                <td>Protocolos de preparación ante estiages. 0 </td>
                <td>${info.C2.toFixed(2)}</td>
            </tr>
            <tr>
                <td>Número de vías de transporte ferroviario. </td>
                <td>${info.C3.toFixed(2)}</td>
            </tr>
            <tr>
                <td>Número de aeropuertos que tengan capacidad para el transporte comercial internacional, por departamento</td>
                <td>${info.C4.toFixed(2)}</td>
            </tr>
            <tr>
                <td><b>Capacidad Media</b></td>
                <td><b>${info.C_MEDIA.toFixed(2)}</b></td>
            </tr>
        </table>
    </div>`, { permanent: false, className: 'table-own' }).openTooltip();}},
            'mouseout': function (e) {
                layer.closeTooltip();
            }})}

    function onEachFeatureRecHidricos(feature, layer) {
        layer.on({
            'click': function (e) {
                if (feature.properties) {
                    let info = feature.properties;
                    layer.bindTooltip(
                        `<h4 style="color: dodgerblue"><b>Unidad Hidrográfica: </b> ${info.DPTO_DESC}</h4>
<b>ND-GAIN: </b> ${info.ND_INDEX.toFixed(2)}<br>

    <b>Indicadores de Vulnerabilidad</b>
    <div class="container">
        <table class="table">
            <tr>
                <td>Demanda Hídrica</td>
                <td>${info.DH.toFixed(2)}</td>
            </tr>
            <tr>
                <td>Riesgo climático</td>
                <td>${info.RC.toFixed(2)}</td>
            </tr>
            <tr>
                <td><b>Vulnerabilidad Media</b></td>
                <td><b>${info.V_MEDIA.toFixed(2)}</b></td>
            </tr>
        </table>
    </div>
    <b>Indicadores de Capacidad</b>
    <div class="container">
        <table class="table">
            <tr>
                <td>Oferta hídrica</td>
                <td>${info.OH.toFixed(2)}</td>
            </tr>
            <tr>
                <td>Gobernanza</td>
                <td>${info.G.toFixed(2)}</td>
            </tr>
            <tr>
                <td><b>Capacidad Media</b></td>
                <td><b>${info.C_MEDIA.toFixed(2)}</b></td>
            </tr>
        </table>
    </div>`, { permanent: false, className: 'table-own' }).openTooltip();}},
            'mouseout': function (e) {
                layer.closeTooltip();
            }})}


    return (
        <Fragment>
            <div className={classes.root}>
                <Header/>
                <MapContainer
                              whenCreated={setMap}
                              className={`${classes.content}`}
                              center={[-23.442503, -58.4438324]}
                              zoom={6}
                              zoomControl={false}
                              scrollWheelZoom={true}>
                    <LayersControl position="topright">
                        <LayersControl.BaseLayer checked name="OSM">
                            <TileLayer
                                attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                            />
                        </LayersControl.BaseLayer>
                        <LayersControl.BaseLayer name="Esri World Imagery">
                            <TileLayer
                                attribution='Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community'
                                url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
                            />
                        </LayersControl.BaseLayer>
                    </LayersControl>
                    <ZoomControl position="topright" />
                    { layerActive === 'Ciudades Resilientes' && ciudadesResilientes ?
                        <GeoJSON ref={ciudadesResilientesRef} style={style} onEachFeature={onEachFeatureCiudadesResilientes} data={ciudadesResilientes} /> : null }
                    { layerActive === 'Ecosistemas y Biodiversidad' && ecosistemas ?
                        <GeoJSON ref={ecosistemasRef} style={style} onEachFeature={onEachFeatureEcoBio} data={ecosistemas} /> : null }
                    { layerActive === 'Energía' && energia ?
                        <GeoJSON ref={energiaRef} style={style} onEachFeature={onEachFeatureEnergia} data={energia} /> : null }
                    { layerActive === 'Producción Agropecuaria Forestal y Seguridad Alimentaria' && prodAgroForestal ?
                        <GeoJSON ref={prodAgroForestalRef} style={style} onEachFeature={onEachFeatureAgroForestal} data={prodAgroForestal} /> : null }
                    { layerActive === 'Recursos Hídricos' && recursosHidricos ?
                        <GeoJSON ref={recursosHidricosRef} style={style} onEachFeature={onEachFeatureRecHidricos} data={recursosHidricos} /> : null }
                    { layerActive === 'Salud y Epidemiología' && saludEpidemiologia ?
                        <GeoJSON ref={saludEpidemiologiaRef} style={style} onEachFeature={onEachFeatureSalud} data={saludEpidemiologia} /> : null }
                    { layerActive === 'Transporte' && transporte ?
                        <GeoJSON ref={transporteRef} style={style} onEachFeature={onEachFeatureTransporte} data={transporte} /> : null }
                    <Legend map={map} />
                    <InfoLayer map={map} layer={layerActive} />
                    <ShowLayerNDGAIN map={map} layer={layerActive} />
                    <LayerDownload layer={layerActive} map={map} />
                </MapContainer>
            </div>
            <Sidebar openVulnerabilidades={true} drawerWidth={drawerWidth} parentCallbackV={handleCallbackV} />
        </Fragment>
    )
}

export default (Vulnerabilidades);
