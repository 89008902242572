import React, { Fragment, useEffect } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Header from "../components/Header/Header";
import makeStyles from '@mui/styles/makeStyles';
import Sidebar from "../components/Sidebar";
import { Alert, Container, CssBaseline, Snackbar, Typography } from "@mui/material";
import { API_URL } from "../utils/config";
import { useGlobalSpinnerActionsContext } from "../context/GlobalSpinnerContext";
import { boolean } from "yup";

const drawerWidth = 120;
const SITE_KEY = "6LdWUuYfAAAAAPDN2DQN6xyPupfoxGqN9CqPHEmR";

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        justifyContent: "flex-start",
        flexDirection: "column",
        alignItems: "center"
    },
    content: {
        flexGrow: 1,
        marginLeft: theme.spacing(9),
        padding: theme.spacing(3),
        top: theme.spacing(7),
        width: `calc(100% - ${drawerWidth}px)`,
        position: "fixed",
        overflowX: "hidden",
        height: `calc(100vh - 104px)`
    },
    button: {
        marginTop: "1rem"
    }

}));

const validationSchema = yup.object({
    name: yup
        .string("Ingresa tu nombre completo")
        .required("El nombre es requerido"),
    email: yup
        .string('Ingresa tu correo de contacto')
        .email('Ingresa una dirección válida de correo electrónico')
        .required('El correo electrónico es requerido'),
    comment: yup
        .string("Ingrese un comentario")
        .required('El mensaje es requerido')
});

const toggleCaptchaBadge = (show: boolean) => {
    const badge = document.getElementsByClassName('grecaptcha-badge')[0];
    if (badge && badge instanceof HTMLElement) {
        badge.style.visibility = show ? 'visible' : 'hidden';
    }
};

const Contacto = () => {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const [message, setMessage] = React.useState('');
    const [severity, setSeverity] = React.useState('success');
    const setGlobalSpinner = useGlobalSpinnerActionsContext();
    const formik = useFormik({
        initialValues: {
            name: '',
            email: '',
            comment: ''
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            window.grecaptcha.ready(() => {
                window.grecaptcha.execute(SITE_KEY, { action: 'submit' }).then(token => {
                    submitData(values, token);
                });
            });
    }});

    // Open alert message
    const handleClick = (message, severity) => {
        setMessage(message);
        setSeverity(severity);
        setOpen(true);
    };
    // Close alert message
    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };

    const submitData = (values, token) => {
        setGlobalSpinner(true);
        values.token = token;
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(values)
        };
        fetch(`${API_URL}escenario_climatico/contact/`, requestOptions)
            .then(res => res.json())
            .then(
                (response) => {
                    if (response && response.success) {
                        formik.resetForm();
                        handleClick('Su solicitud fue enviada exitosamente.', 'success');
                    } else {
                        handleClick('No se pudo enviar el correo. Favor vuelva a reintentar en unos minutos.', 'error');
                    }
                    setGlobalSpinner(false);},
                (error) => {
                    setGlobalSpinner(false);
                    console.error(error);
                    handleClick('Ocurrió un error al enviar el mensaje.', 'error');
                })
    }

    useEffect(() => {
        const loadScriptByURL = (id, url, callback) => {
            const isScriptExist = document.getElementById(id);

            if (!isScriptExist) {
                var script = document.createElement("script");
                script.type = "text/javascript";
                script.src = url;
                script.id = id;
                script.onload = function () {
                    if (callback) callback();
                };
                document.body.appendChild(script);
            }

            if (isScriptExist && callback) callback();
        }

        // load the script by passing the URL
        loadScriptByURL("recaptcha-key", `https://www.google.com/recaptcha/api.js?render=${SITE_KEY}`, function () {
            console.log("Script loaded!");
        });
        // HIDE in another pages
        toggleCaptchaBadge(true);
        return () => toggleCaptchaBadge(false);
    }, []);

    return (
        <Fragment>
            <div className={classes.root}>
                <Header/>
                <CssBaseline />
                <Container fixed className={`${classes.root} ${classes.content}`}>
                    <Typography>
                        <h2>Contáctenos</h2>
                    </Typography>
                    <form onSubmit={formik.handleSubmit}>
                        <TextField
                            fullWidth
                            size="small"
                            variant="standard"
                            id="name"
                            name="name"
                            label="Nombre"
                            value={formik.values.name}
                            onChange={formik.handleChange}
                            error={formik.touched.name && Boolean(formik.errors.name)}
                            helperText={formik.touched.name && formik.errors.name}
                        />
                        <TextField
                            fullWidth
                            size="small"
                            id="email"
                            variant="standard"
                            name="email"
                            label="Correo electrónico"
                            value={formik.values.email}
                            onChange={formik.handleChange}
                            error={formik.touched.email && Boolean(formik.errors.email)}
                            helperText={formik.touched.email && formik.errors.email}
                        />
                        <TextField
                            fullWidth
                            size="small"
                            variant="standard"
                            id="comment"
                            name="comment"
                            label="Comentario"
                            multiline
                            minRows={4}
                            maxRows={10}
                            value={formik.values.comment}
                            onChange={formik.handleChange}
                            error={formik.touched.comment && Boolean(formik.errors.comment)}
                            helperText={formik.touched.comment && formik.errors.comment}
                        />
                        <Button color="primary" variant="contained" type="submit" className={classes.button}>
                            Enviar
                        </Button>
                    </form>
                </Container>
            </div>
            <Sidebar open={true} drawerWidth={drawerWidth} />
            <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                             open={open} autoHideDuration={6000} onClose={handleClose}>
                <Alert onClose={handleClose} severity={severity} sx={{ width: '100%' }}>
                    {message}
                </Alert>
        </Snackbar>
        </Fragment>
    );
};

export default (Contacto);
