import React, { useEffect } from "react";
import Drawer from "@mui/material/Drawer";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import TravelExploreIcon from "@mui/icons-material/TravelExplore";
import ContactSupportIcon from "@mui/icons-material/ContactSupport";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import FolderSpecialIcon from "@mui/icons-material/FolderSpecial";
import HelpIcon from "@mui/icons-material/Help";
import { Link } from "react-router-dom";
import makeStyles from '@mui/styles/makeStyles';
import Popper from "@mui/material/Popper";
import {
  Button,
  Checkbox,
  Chip,
  Container,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Grid,
  IconButton,
  Input,
  MenuItem,
  MenuList,
  Popover,
  Radio,
  RadioGroup,
  Select,
  Tooltip,
  Typography
} from "@mui/material";
import { API_URL } from "../../utils/config";
import Toolbar from "@mui/material/Toolbar";
import { useGlobalSpinnerActionsContext, useGlobalSpinnerContext } from "../../context/GlobalSpinnerContext";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  drawerPaper: {
    position: "fixed",
    top: theme.spacing(8),
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  drawerPaperClose: {
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    width: theme.spacing(8),
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9)
    }
  },
  paper: {
    // border: '1px solid',
    // padding: theme.spacing(1),
    backgroundColor: "teal",
    color: "white"
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const Sidebar = props => {
  const classes = useStyles();
  const setGlobalSpinner = useGlobalSpinnerActionsContext();
  const isGlobalSpinnerOn = useGlobalSpinnerContext();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorEl2, setAnchorEl2] = React.useState(null);
  const [anchorElPopover, setAnchorElPopover] = React.useState(null);
  const [anchorElPopover2, setAnchorElPopover2] = React.useState(null);
  const [anchorElPopover3, setAnchorElPopover3] = React.useState(null);
  const [error, setError] = React.useState(null);
  const [isDisabled, setIsDisabled] = React.useState(false);
  const [unidad, setUnidad] = React.useState('personalizar');
  const [unidadTerritorio, setUnidadTerritorio] = React.useState('departamento');
  const [departamentos, setDepartamentos] = React.useState([]);
  const [variables, setVariables] = React.useState([]);
  const [tiposValor, setTiposValor] = React.useState([]);
  const [escenarios, setEscenarios] = React.useState([]);
  const [periodos, setPeriodos] = React.useState([]);
  const [unidadesHidrograficas, setUnidadesHidrograficas] = React.useState([]);
  const [sectorVulnerabilidades] = React.useState(
      ['Ciudades Resilientes', 'Ecosistemas y Biodiversidad', 'Energía',
        'Producción Agropecuaria Forestal y Seguridad Alimentaria', 'Recursos Hídricos', 'Salud y Epidemiología',
        'Transporte']);
  const [sectorVulnerabilidad, setSectorVulnerabilidad] = React.useState('');
  const handleChangesectorVulnerabilidad = (event) => {
    setSectorVulnerabilidad(event.target.value);
  };
  const [variable, setVariable] = React.useState('10');
  const [division, setDivision] = React.useState('dpto');
  const [tipoValor, setTipoValor] = React.useState('10');
  const [escenario, setEscenario] = React.useState('10');
  const [periodo, setPeriodo] = React.useState('10');
  const [mostrarDpto, setMostrarDpto] = React.useState(true);
  const [dptoName, setDptoName] = React.useState([]);
  const [esHistorico, setEsHistorico] = React.useState(false);
  const [tipoValorDisabled, setTipoValorDisabled] = React.useState(false);
  const [showTipoValor, setShowTipoValor] = React.useState(true);
  const [unidadDisabled, setUnidadDisabled] = React.useState(false);
  const [unidadHidrograficaName, setUnidadHidrograficaName] = React.useState([]);
  const open = Boolean(anchorEl);
  const openVulnerabilidades = Boolean(anchorEl2);
  const id = open ? 'simple-popper' : undefined;
  const idVulnerabilidades = openVulnerabilidades ? 'simple-popper' : undefined;

  const openPopover = Boolean(anchorElPopover);
  const openPopover2 = Boolean(anchorElPopover2);
  const openPopover3 = Boolean(anchorElPopover3);
  const idPopover = openPopover ? 'simple-popover' : undefined;
  const idPopover2 = openPopover2 ? 'simple-popover' : undefined;
  const idPopover3 = openPopover3 ? 'simple-popover' : undefined;

  const handleClickPopover = (event) => {
    setAnchorElPopover(event.currentTarget);
  };

  const handleClosePopover = () => {
    setAnchorElPopover(null);
  };

  const handleClickPopover2 = (event) => {
    setAnchorElPopover2(event.currentTarget);
  };

  const handleClosePopover2 = () => {
    setAnchorElPopover2(null);
  };

  const handleClickPopover3 = (event) => {
    setAnchorElPopover3(event.currentTarget);
  };

  const handleClosePopover3 = () => {
    setAnchorElPopover3(null);
  };


  useEffect(() => {
    setGlobalSpinner(true);
    fetch(`${API_URL}mapa_base/departamento/`)
        .then(res => res.json())
        .then(
            (result) => {
              setDepartamentos(result);
              loadUnidadHidrografica();
            },
            (error) => {
              setGlobalSpinner(false);
              setError(error);
            }
        )
  }, [])

  const loadUnidadHidrografica = () => {
    fetch(`${API_URL}mapa_base/unidad_hidrografica/`)
        .then(res => res.json())
        .then(
            (result) => {
              setUnidadesHidrograficas(result);
              loadParametros();
            },
            (error) => {
              setGlobalSpinner(false);
              setError(error);
            }
        )
  }

  const loadParametros = () => {
    fetch(`${API_URL}escenario_climatico/parametro/`)
        .then(res => res.json())
        .then(
            (result) => {
              setGlobalSpinner(false);
              filterVariables(result);
              filterTiposValor(result);
              filterEscenarios(result);
              filterPeriodos(result);
            },
            (error) => {
              setGlobalSpinner(false);
              setError(error);
            }
        )
  }

  const handleChangeUnidadTerritorio = (event) => {
    let seleccionado = event.target.value;
    setUnidadTerritorio(seleccionado);
    if (seleccionado === 'departamento') {
      setDivision('dpto');
      setMostrarDpto(true);
    } else {
      setDivision('uh');
      setMostrarDpto(false);
    }
    cleanSeleccion();
  };

  const cleanSeleccion = () => {
    setDptoName([]);
    setUnidadHidrograficaName([]);
    setUnidad("personalizar");
  }

  const handleChangeVariable = (event) => {
    let newValue = event.target.value;
    let oldValue = variable.valor;
    let esPrecipitacion = newValue.valor === 'prec';

    setVariable(newValue);

    if (esPrecipitacion || oldValue === 'prec') {
      setShowTipoValor(!showTipoValor);
    }

  };

  const handleChangeTipoValor = (event) => {
    setTipoValor(event.target.value);
  };

  const handleChangeEscenario = (event) => {
    setEscenario(event.target.value);
    setIsDisabled(false);
  };

  const handleChangePeriodo = (event) => {
    let newValue = event.target.value;

    setTipoValorDisabled(false);
    if (newValue.valor === '1990') {
      setTipoValorDisabled(true);
      setTipoValor("");
    }
    if (newValue.valor === '1990' || periodo.valor === '1990') { // Si estaba seleccionado o se selecciona historico
      setEscenario("");
      setIsDisabled(true);
    }
    if (periodo.valor === '1990') {
      setTipoValor(tiposValor.filter(param => param.valor === 'abs')[0]);
    }
    setEsHistorico(newValue.valor === '1990');
    setPeriodo(newValue);
  }

  const handleClose = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const handleToggle = (event) => {
    setAnchorEl2(anchorEl2 ? null : event.currentTarget);
  };

  const handleChangeDepartamento = event => {
    setDptoName(event.target.value);
  };

  const handleChangeUnidadHidrografica = event => {
    setUnidadHidrograficaName(event.target.value);
  };

  const handleChangeUnidad = (event) => {
    let valorRegion = event.target.value;
    setUnidad(valorRegion);
    if (valorRegion !== "personalizar") {
      // Deshabilitar input
      setUnidadDisabled(true);
      if (mostrarDpto) {
        setDptoName(departamentos.filter(dpto => { return dpto.region === valorRegion.toUpperCase()}))
      } else {
        setUnidadHidrograficaName(unidadesHidrograficas.filter(uh => { return uh.region === valorRegion.toUpperCase()}))
      }
    } else {
      // Habilitar input
      setDptoName([]);
      setUnidadHidrograficaName([]);
      setUnidadDisabled(false);
    }
  }

  const filterVariables = (parametros) => {
    let results = parametros.filter((parametro) => {
      if (parametro.tipo === 'VARIABLE') {
        if (parametro.valor === 'tp2m') {
          setVariable(parametro);
        }
        return parametro
      }
      return null;
    });
    setVariables(results);
  }

  const filterTiposValor = (parametros) => {
    let results = parametros.filter((parametro) => {
      if (parametro.tipo === 'TIPO_VALOR') {
        if (parametro.valor === 'abs') {
          setTipoValor(parametro);
        }
        if (parametro.valor === 'por'){
          return null;
        }
        return parametro
      }
      return null;
    });
    setTiposValor(results);
  }

  const filterEscenarios = (parametros) => {
    let results = parametros.filter((parametro) => {
      if (parametro.tipo === 'ESCENARIO') {
        if (parametro.valor === 'rcp45') {
          setEscenario(parametro)
        }
        return parametro
      }
      return null;
    });
    setEscenarios(results);
  }

  const filterPeriodos = (parametros) => {
    let results = parametros.filter((parametro) => {
      if (parametro.tipo === 'PERIODO') {
        if (parametro.valor === '2040') {
          setPeriodo(parametro);
        }
        return parametro
      }
      return null;
    });
    setPeriodos(results)
  }

  const renderVariables = () => {
    return variables.map((variable) => {
      return (
        <MenuItem key={variable.id} value={variable}>
          <ListItemText primary={variable.descripcion} />
        </MenuItem>
      )
    })
  };

  const renderTipoValor = () => {
    return tiposValor.map((tipoValor) => {
      return (
          <MenuItem key={tipoValor.id} value={tipoValor}>
            <ListItemText primary={tipoValor.descripcion} />
          </MenuItem>
      )
    })
  }

  const renderPeriodos = () => {
    return periodos.map((periodo) => {
      return (
          <MenuItem key={periodo.id} value={periodo}>
            <ListItemText primary={periodo.descripcion} />
          </MenuItem>
      )
    })
  };

  const renderEscenarios = () => {
    return escenarios.map((escenario) => {
      if (escenario.valor === 'historico') {
        return (
            <MenuItem key={escenario.id} disabled={!esHistorico} value={escenario}>
              <ListItemText primary={escenario.descripcion} />
            </MenuItem>
        )
      } else {
        return (
            <MenuItem key={escenario.id} disabled={esHistorico} value={escenario}>
              <ListItemText primary={escenario.descripcion} />
            </MenuItem>
        )
      }
    })
  };

  const submitFilters = event => {
    let ids = unidadTerritorio === 'departamento' ? dptoName : unidadHidrograficaName
    let tipoValorFinal = tipoValor.valor;
    if (variable.valor === 'prec') {
      tipoValorFinal = 'por';
    } else if (escenario.valor === 'historico') {
      tipoValorFinal = 'abs';
    }
    props.parentCallback({
      unidadTerritorio: unidadTerritorio,
      ids: ids,
      layerName: `${division}${variable.valor}${tipoValorFinal}${escenario.valor}${periodo.valor}`})
  }

  const displaySectorVulnerable = event => {
    props.parentCallbackV({
      sectorVulnerable: sectorVulnerabilidad
    })
  }

  return (
    <Drawer
      variant="permanent"
      classes={{ paper: `${classes.paper} ${classes.drawerPaper} ${classes.drawerPaperClose}`}}
      open={true}
    >
      <Toolbar style={{height: 0, minHeight: 0, maxHeight:0}}/>
      <MenuList style={{paddingTop: "0px"}}>
        <Link to="/">
          <MenuItem style={{justifyContent: "center"}} aria-describedby={id} onClick={handleClose}>
            <Tooltip title="Filtros" aria-label="filtros" placement="right">
              <ListItemIcon style={{justifyContent: "center", color: "white"}}>
                <FilterAltIcon />
              </ListItemIcon>
            </Tooltip>
          </MenuItem>
        </Link>
        <Link to="/vulnerabilidades">
          <MenuItem style={{justifyContent: "center"}} aria-describedby={idVulnerabilidades} onClick={handleToggle}>
            <Tooltip title="Mapas de vulnerabilidades" aria-label="Mapas de vulnerabilidades" placement="right">
              <ListItemIcon style={{justifyContent: "center", color: "white"}}>
                <TravelExploreIcon />
              </ListItemIcon>
            </Tooltip>
          </MenuItem>
        </Link>
        <Link to="/manual_usuario">
          <MenuItem style={{justifyContent: "center"}}>
            <Tooltip title="Manual de Usuario" aria-label="manual-usuario" placement="right">
              <ListItemIcon style={{justifyContent: "center", color: "white"}}>
                <PictureAsPdfIcon />
              </ListItemIcon>
            </Tooltip>
          </MenuItem>
        </Link>
        <Link to="/contacto">
          <MenuItem style={{justifyContent: "center"}}>
            <Tooltip title="Contáctanos" aria-label="contact us" placement="right">
              <ListItemIcon style={{justifyContent: "center", color: "white"}}>
                <ContactSupportIcon />
              </ListItemIcon>
            </Tooltip>
          </MenuItem>
        </Link>
        <a target="_blank" rel="noreferrer" href="https://www.datos.gov.py/search/type/dataset">
          <MenuItem style={{justifyContent: "center"}}>
            <Tooltip title="Dataset" aria-label="dataset" placement="right">
              <ListItemIcon style={{justifyContent: "center", color: "white"}}>
                <FolderSpecialIcon />
              </ListItemIcon>
            </Tooltip>
          </MenuItem>
        </a>
      </MenuList>
      <Popper id={id} open={open} anchorEl={anchorEl} placement={'right-start'} style={{zIndex: 1200}}>
        <Container style={{ backgroundColor: "white", border: "1px solid lightgray" }} component="main" maxWidth="xs">
          <Grid container style={{ display: "flex", flexDirection: "column"}}>
            <Typography variant="h6">Cambio Climático</Typography>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} style={{ display: "flex", flexDirection: "column"}}>
              <FormControl component="fieldset" style={{marginTop: "1rem"}}>
                <FormLabel component="legend">
                  Configuración de parámetros
                </FormLabel>
                <Divider />
                <RadioGroup row aria-label="position" name="filtro" defaultValue="top"
                            value={unidadTerritorio} onChange={handleChangeUnidadTerritorio}>
                  <FormControlLabel value="departamento" control={<Radio color="primary" size="small"/>} label="Departamento" />
                  <FormControlLabel value="unidad_hidrografica" control={<Radio color="primary" size="small"/>} label="Unidades Hidrográficas" />
                </RadioGroup>
              </FormControl>
              <FormControl component="fieldset" style={{marginTop: "0.5rem", flexDirection: "row" }}>
                <RadioGroup row aria-label="position" name="unidad" defaultValue="personalizar"
                            value={unidad} onChange={handleChangeUnidad}>
                  <FormControlLabel value="oriental" control={<Radio color="primary" size="small"/>} label="Región Oriental" />
                  <FormControlLabel value="occidental" control={<Radio color="primary" size="small"/>} label="Región Occidental" />
                  <FormControlLabel value="personalizar" control={<Radio color="primary" size="small"/>} label="Personalizada" />
                </RadioGroup>
              </FormControl>
            </Grid>
            { mostrarDpto ?
                <Grid item xs={12} sm={12} style={{ display: "flex", flexDirection: "column"}}>
              <FormControl variant="outlined" className={classes.formControl} size="small">
                <Select
                    labelId="demo-mutiple-checkbox-label"
                    id="demo-mutiple-checkbox"
                    multiple
                    value={dptoName}
                    displayEmpty
                    disabled={unidadDisabled}
                    onChange={handleChangeDepartamento}
                    input={<Input id="select-multiple-chip" />}
                    renderValue={(selected) => (
                        <div className={classes.chips}>
                          {selected.map((value) => (
                              <Chip key={value.id} label={value.nombre} className={classes.chip} />
                          ))}
                        </div>
                    )}
                    MenuProps={MenuProps}
                >
                  {departamentos.map((depto) => (
                      <MenuItem key={depto.id} value={depto}>
                        <Checkbox checked={dptoName.indexOf(depto) > -1} />
                        <ListItemText primary={depto.nombre} />
                      </MenuItem>
                  ))}
                </Select>
                <FormHelperText>Seleccione los Departamentos que desea filtrar</FormHelperText>
              </FormControl>
            </Grid> :
            <Grid item xs={12} sm={12} style={{ display: "flex", flexDirection: "column"}}>
              <FormControl variant="outlined" className={classes.formControl} size="small">
                <Select
                    labelId="unidad-hidrografica-mutiple-checkbox-label"
                    id="unidad-hidrografica-mutiple-checkbox"
                    multiple
                    value={unidadHidrograficaName}
                    displayEmpty
                    onChange={handleChangeUnidadHidrografica}
                    input={<Input id="select-unidad-hidrografica-multiple-chip" />}
                    renderValue={(selected) => (
                        <div className={classes.chips}>
                          {selected.map((value) => (
                              <Chip key={value.id} label={value.nombre} className={classes.chip} />
                          ))}
                        </div>
                    )}
                    MenuProps={MenuProps}
                >
                  {unidadesHidrograficas.map((unidadHidrografica) => (
                      <MenuItem key={unidadHidrografica.id} value={unidadHidrografica}>
                        <Checkbox checked={unidadHidrograficaName.indexOf(unidadHidrografica) > -1} />
                        <ListItemText primary={unidadHidrografica.nombre} />
                      </MenuItem>
                  ))}
                </Select>
                <FormHelperText>Seleccione las Unidades Hidrográficas que desea filtrar</FormHelperText>
              </FormControl>
            </Grid>
            }
            <Grid item xs={12} sm={12} style={{ display: "flex", flexDirection: "column"}}>
              <FormControl className={classes.formControl} size="small">
                <FormLabel component="legend">
                  Variable
                  <IconButton aria-label="info" aria-describedby={idPopover} variant="contained" onClick={handleClickPopover}>
                    <HelpIcon color="primary" />
                  </IconButton>
                  <Popover id={idPopover} open={openPopover} anchorEl={anchorElPopover} onClose={handleClosePopover}
                      anchorOrigin={{
                        vertical: 'center',
                        horizontal: 'center',
                      }}
                      transformOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                      }}
                  >
                    Las variables ...
                  </Popover>
                </FormLabel>
                <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    value={variable}
                    onChange={handleChangeVariable}
                >
                  { renderVariables() }
                </Select>
              </FormControl>
              { showTipoValor && <FormControl className={classes.formControl} size="small">
                <Select
                    labelId="label-select-tipo-valor"
                    id="select-tipo-valor"
                    value={tipoValor}
                    disabled={tipoValorDisabled}
                    onChange={handleChangeTipoValor}
                >
                  { renderTipoValor() }
                </Select>
              </FormControl> }
            </Grid>
            <Grid item xs={12} sm={12} style={{ display: "flex", flexDirection: "column"}}>
              <FormControl className={classes.formControl} size="small">
                <FormLabel component="legend">
                  Periodo
                  <IconButton aria-label="info" aria-describedby={idPopover2} variant="contained" onClick={handleClickPopover2}>
                    <HelpIcon color="primary" />
                  </IconButton>
                  <Popover id={idPopover2} open={openPopover2} anchorEl={anchorElPopover2} onClose={handleClosePopover2}
                           anchorOrigin={{
                             vertical: 'center',
                             horizontal: 'center',
                           }}
                           transformOrigin={{
                             vertical: 'bottom',
                             horizontal: 'left',
                           }}
                  >
                    El periodo ...
                  </Popover>
                </FormLabel>
                <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    value={periodo}
                    onChange={handleChangePeriodo}
                >
                  { renderPeriodos() }
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12} style={{ display: "flex", flexDirection: "column"}}>
              <FormControl className={classes.formControl} size="small">
                <FormLabel component="legend">
                  Escenarios
                  <IconButton aria-label="info" aria-describedby={idPopover3} variant="contained" onClick={handleClickPopover3}>
                    <HelpIcon color="primary" />
                  </IconButton>
                  <Popover id={idPopover3} open={openPopover3} anchorEl={anchorElPopover3} onClose={handleClosePopover3}
                           anchorOrigin={{
                             vertical: 'center',
                             horizontal: 'center',
                           }}
                           transformOrigin={{
                             vertical: 'bottom',
                             horizontal: 'left',
                           }}
                  >
                    Los escenarios ...
                  </Popover>
                </FormLabel>
                <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    value={escenario}
                    onChange={handleChangeEscenario}
                >
                  { renderEscenarios() }
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <Grid container justifyContent="space-evenly" direction="row" alignItems="center"
                style={{marginBottom: "1rem", marginTop: "1rem"}} >
            <Button variant="contained" color="primary" disableElevation onClick={() => submitFilters()}
                    disabled={isDisabled || isGlobalSpinnerOn}>
              Aplicar
            </Button>
            <Button variant="contained" disableElevation onClick={handleClose}>
              Cerrar
            </Button>
          </Grid>
        </Container>
      </Popper>
      <Popper id={idVulnerabilidades} open={openVulnerabilidades} anchorEl={anchorEl2} placement={'right-start'} style={{zIndex: 1200}}>
        <Container style={{ backgroundColor: "white", border: "1px solid lightgray" }} component="main" maxWidth="xs">
          <Grid container style={{ display: "flex", flexDirection: "column"}}>
            <Divider><Typography variant="h6">Sectores Priorizados de Adaptación</Typography></Divider>
          </Grid>
          <Grid container spacing={2}>
                <Grid item xs={12} sm={12} style={{ display: "flex", flexDirection: "column"}}>
                  <FormControl variant="outlined" className={classes.formControl} size="small">
                    <FormLabel component="legend">
                      Sector
                    </FormLabel>
                    <Select
                        labelId="sectores-vulnerabilidades"
                        id="sectores-vulnerabilidades"
                        value={sectorVulnerabilidad}
                        displayEmpty
                        onChange={(e) => handleChangesectorVulnerabilidad(e)}
                    >
                      {sectorVulnerabilidades.map((sector) => (
                          <MenuItem value={sector}>{sector}</MenuItem>
                      ))}
                    </Select>
                    <FormHelperText>Seleccione el Sector de Vulnerabilidad </FormHelperText>
                  </FormControl>
                </Grid>
          </Grid>
          <Grid container justifyContent="space-evenly" direction="row" alignItems="center"
                style={{marginBottom: "1rem", marginTop: "1rem"}} >
            <Button variant="contained" color="primary" disableElevation onClick={() => displaySectorVulnerable()}
                    disabled={isDisabled || isGlobalSpinnerOn}>
              Aplicar
            </Button>
            <Button variant="contained" disableElevation onClick={handleToggle}>
              Cerrar
            </Button>
          </Grid>
        </Container>
      </Popper>
    </Drawer>
  );
};

export default Sidebar;
