import React, { Fragment, useEffect } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Header from "../components/Header/Header";
import Sidebar from "../components/Sidebar";
import { Button, Container, Grid } from "@mui/material";
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import FileSaver from 'file-saver';


const drawerWidth = 120;

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
    },
    content: {
        flexGrow: 1,
    },
    button: {
        margin: 4
    }
}));


const ManualUsuario = props => {
    const classes = useStyles();

    useEffect(() => {
    }, [])

    return (
        <Fragment>
            <div className={classes.root}>
                <Header/>
                <Container maxWidth="md" style={{position: "relative", top: 64, paddingBottom: "2rem",
                    paddingRight: "6rem", paddingLeft: "6rem"}}>
                    <Grid container spacing={2} justifyContent="center" style={{textAlign: "justify"}}>
                        <h1>
                            Visor de Escenarios Climáticos Paraguay
                        </h1>
                        <p>Bienvenido al <strong>Visor de Escenarios de Cambio Climático, Vulnerabilidad y Adaptación
                            Paraguay</strong> una plataforma de fácil acceso para conocer, visualizar y descargar los
                            datos relativos al clima futuro del Paraguay. Aquí podrás consultar las proyecciones
                            regionalizadas de cambio climático por departamento y unidades hidrográficas. Además,
                            información sobre la vulnerabilidad y adaptación al cambio climático de Paraguay, dividido
                            en siete sectores.
                        </p>
                        <p>
                            Las proyecciones de cambio climático para el Paraguay fueron realizadas a partir de las
                            proyecciones de modelos climático regional ETA (MCR), impulsado por los modelos climáticos
                            globales (MCG) HadGEM2-ES, BESM, CANESM2 y MIROC5 de forma ensamblada.
                        </p>
                        <p>
                            Los datos disponibles se nutren principalmente de dos fuentes: datos históricos del CRU
                            (Unidad de Investigación del Clima, por sus siglas en Ingles) de la universidad de East
                            Anglia del Reino Unido (CRU TS 4.04: Climatic Research Unit (CRU) Time-Series (TS) version
                            4.04 of high-resolution gridded data of month-by-month variation in climate (Jan. 1901- Dec.
                            2019)  y proyecciones en rejilla procedentes de la plataforma internacional <a
                            href="https://projeta.cptec.inpe.br/#/dashboard" rel="noreferrer" target="_blank">
                            PROJETA</a> (Proyecciones de Cambio Climático para América del Sur regionalizadas por el
                            modelo ETA), cuyos datos son generados por el CPTEC/INPE.
                        </p>
                        <p>
                            Para el caso de la vulnerabilidad ante el cambio climático, dichas características han sido
                            discriminadas en función a los sectores a los cuales se quiere determinar su vulnerabilidad.
                            Con la Primera Comunicación de Adaptación se han determinado siete sectores prioritarios a
                            ser analizados que son: Sector 1: Comunidades y ciudades resilientes, Sector 2: Salud y
                            epidemiología, Sector 3: Ecosistemas y biodiversidad, Sector 4: Energía, Sector 5:
                            Producción agropecuaria, forestal y seguridad alimentaria, Sector 6: Recursos hídricos y
                            Sector 7: Transporte. Cada sector posee características propias y deben ser analizados
                            individualmente, sin embargo, pueden ser susceptibles a un mismo tipo de amenaza, pero
                            este puede causar impactos diferentes, incluso lo que para un sector puede resultar un
                            peligro, para otro sector podría resultar en un beneficio o viceversa. Asimismo, la
                            temporalidad, intensidad y magnitud de estos eventos también pueden indicar la ocurrencia o
                            no de una amenaza. Los detalles técnicos e información complementaria se encuentra en el <a
                            target="_blank" href={`${process.env.PUBLIC_URL}/capitulo_vulnerabilidad.pdf`}><strong>
                            CAPÍTULO VULNERABILIDAD ANTE EL CAMBIO CLIMÁTICO</strong></a>, que se trata de un fragmento
                            de la CUARTA COMUNICACIÓN NACIONAL DE CAMBIO CLIMÁTICO: CAPÍTULO DE VULNERABILIDAD ANTEL EL
                            CAMBIO CLIMÁTICO 2022.
                        </p>
                        <p>
                            El <a target="_blank" href={`${process.env.PUBLIC_URL}/manual_visor.pdf`}><strong>
                            Manual de Usuario</strong></a> sintetiza el funcionamiento del visor, los parámetros
                            disponibles y las formas de visualizar los datos. También contiene información completa
                            sobre los datos y modelos utilizados. Esta herramienta tiene una vocación de mejora
                            progresiva y ajuste a las necesidades de los usuarios por lo que no dudes en hacernos llegar
                            tus comentarios o sugerencias.
                        </p>
                        <p>
                            Este visor ha sido desarrollado en el marco del proyecto N° 00115141 “Cuarta Comunicación
                            Nacional y Tercer Informe Bienal de Actualización de la República del Paraguay”, Ministerio
                            del Ambiente y Desarrollo Sostenible (MADES) y el Programa de las Naciones Unidas para el
                            Desarrollo (PNUD). Con el apoyo técnico de la Dirección de Meteorología e Hidrología (DMH)
                            dependiente de la Dirección Nacional de Aeronáutica Civil (DINAC).
                        </p>
                        <div>
                            <Button
                                variant="contained"
                                color="primary"
                                className={classes.button}
                                onClick={() => {FileSaver.saveAs(
                                    process.env.PUBLIC_URL + "/manual_visor.pdf",
                                    "Manual de Usuario.pdf")}}
                                startIcon={<CloudDownloadIcon />}
                            >Manual de Usuario</Button>
                            <Button
                                variant="contained"
                                color="primary"
                                className={classes.button}
                                onClick={() => {FileSaver.saveAs(
                                    process.env.PUBLIC_URL + "/capitulo_vulnerabilidad.pdf",
                                    "Capítulo Vulnerabilidad.pdf")}}
                                startIcon={<CloudDownloadIcon />}
                            >Cap. Vulnerabilidad</Button>
                        </div>
                    </Grid>
                </Container>
            </div>
            <Sidebar open={true} drawerWidth={drawerWidth} />
        </Fragment>
    );
}

export default (ManualUsuario);
