import React, { Fragment, useEffect } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Header from "../components/Header/Header";
import Sidebar from "../components/Sidebar";
import { Button, Container, Grid } from "@mui/material";
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import FileSaver from 'file-saver';


const drawerWidth = 120;

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
    },
    content: {
        flexGrow: 1,
    },
    button: {
        margin: 4
    }
}));


const PrivacyPolicies = props => {
    const classes = useStyles();

    useEffect(() => {
    }, [])

    return (
        <Fragment>
            <div className={classes.root}>
                <Header/>
                <Container maxWidth="md" style={{position: "relative", top: 64, paddingBottom: "2rem",
                    paddingRight: "6rem", paddingLeft: "6rem"}}>
                    <Grid container spacing={2} justifyContent="center" style={{textAlign: "justify", width: '100%'}}>
                        <h2>TÉRMINOS Y CONDICIONES DE USO</h2>
                        <h3>VISOR DE ESCENARIOS DE CAMBIO CLIMÁTICO, VULNERABILIDAD Y ADAPTACIÓN PARAGUAY</h3>

                        <h3>CONDICIONES GENERALES</h3>
                        <p>El portal web que contiene el Visor de Escenarios de Cambio Climático, Vulnerabilidad y Adaptación
                            Paraguay (https://visorweb-dncc.mades.gov.py, en adelante “el visor web”) ha sido desarrollado en el
                            marco del Proyecto N° 00115141 “Cuarta Comunicación Nacional y Tercer Informe Bienal de
                            Actualización de la República del Paraguay – CCN e IBA3”, por el Ministerio del Ambiente y Desarrollo
                            Sostenible (MADES) de la Presidencia de la República de Paraguay, con sede en la ciudad de
                            Asunción, Paraguay; y tiene como principal finalidad presentar en forma completa y organizada
                            informaciones actualizadas referente a los escenarios de cambio climático, vulnerabilidades y
                            adaptación del Paraguay. Los datos suministrados están en formato abierto, con el fin de que éstos
                            puedan ser usados de forma libre y sin restricciones por cualquier persona interesada.
                        </p>
                        <p>
                            Las presentes condiciones generales vinculan a cualquier persona natural o jurídica que haga uso,
                            aprovechamiento y/o trasformación de los datos y la información publicados en el visor web.
                        </p>

                        <h3>ACEPTACIÓN DE LOS TÉRMINOS</h3>
                        <p>
                            El MADES solicita al Usuario que lea detallada y reflexivamente estas condiciones de uso y la política
                            de privacidad de este Portal (en adelante las Condiciones de Uso), antes de iniciar su exploración o
                            utilización.
                        </p>
                        <p>
                            La utilización de este Portal implica la aceptación expresa y plena de las condiciones generales de
                            acceso y uso de la versión publicada, en el instante en que el usuario acceda al mismo, sin perjuicio
                            de las circunstancias particulares que pudieran aplicarse a algunos contenidos o servicios en
                            concreto. Si el Usuario no está de acuerdo con estas Condiciones de Uso o con cualquier disposición
                            de la Política de Privacidad, le sugerimos que se abstenga de acceder o navegar por este Portal.
                        </p>

                        <h3>LICENCIA DE USO DE LA INFORMACIÓN Y LOS DATOS ABIERTOS PÚBLICOS PROPIEDAD DEL ESTADO PARAGUAYO</h3>
                        <p>
                            Esta licencia otorga la autorización gratuita, perpetua y no exclusiva de uso y/o transformación de la
                            información y los datos abiertos públicos propiedad del Estado Paraguayo a cualquier persona física o
                            jurídica que haga uso de los mismos.
                        </p>
                        <p>
                            Para efectos de la presente licencia, se entiende por uso y/o transformación autorizada de la
                            información o los datos públicos, las actividades tales como: copia, extracción, reproducción,
                            distribución, comunicación pública, adaptación, transformación y todo aquel uso lícito en cualquier
                            modalidad y bajo cualquier formato, por el plazo máximo permitido por la Ley.
                        </p>

                        <h4>Condiciones de uso y/o transformación de la información o los datos</h4>
                        <p>
                            La licencia de uso estará vigente siempre que el usuario cumpla con las siguientes condiciones:<br/><br/>
                            a) Citar a la fuente pública que proveyó la información o los datos objeto del uso y/o transformación; y que el contenido se rige por la presente licencia.<br/>
                            b) Citar la fecha de la última actualización de la información o los datos objeto del uso y/o transformación, siempre y cuando esto fuera conocido.<br/>
                            c) No usar la información pública ni los datos abiertos públicos de forma que sugiera o simule un uso oficial o patrocinado por el Estado Paraguayo.
                        </p>

                        <h4>Exclusión de responsabilidad</h4>
                        <p>
                            El uso y/o transformación de la información pública o los datos lo realiza el usuario, bajo su exclusiva
                            responsabilidad y riesgo.
                        </p>

                        <p>
                            En ningún caso la fuente pública proveedora de información o los datos será responsable del uso que
                            el usuario haga de la misma, ni tampoco de ningún daño económico o de cualquier índole que, en
                            forma directa o indirecta, se derive u ocurra a terceros sobre sus bienes, equipos, actividad comercial,
                            datos y aplicaciones, como consecuencia de dicho uso y/o transformación de los datos.
                        </p>

                        <p>
                            Cuando el uso o tratamiento de la información esté sujeto a algún tipo de restricción deberá estarse a
                            lo indicado expresamente en ese caso, conforme la legislación que así lo disponga. La información de
                            carácter personal será tratada conforme a la legislación vigente en la materia y lo dispuesto en los
                            presentes términos y condiciones.
                        </p>

                        <h3>RESPONSABILIDADES DE LOS USUARIOS</h3>
                        <p>Tanto el acceso al Visor Web como el uso sin consentimiento que pueda efectuarse de la información
                            contenida en el mismo, es de exclusiva responsabilidad de quien lo realiza. El MADES no responderá
                            de ninguna consecuencia, daño o perjuicio que pueda derivarse de dicho acceso o uso.
                        </p>

                        <p>
                            El ingreso a los sitios enlazados, es de única y exclusiva responsabilidad del Usuario, quien deberá ser
                            responsable del cumplimiento y aceptación de los Términos y Condiciones de Uso y Privacidad que
                            sean establecidos en cada uno de estos sitios.
                        </p>

                        <p>
                            Los Usuarios se obligan a hacer buen uso del sitio Web y de sus contenidos, respetando la normativa
                            nacional vigente, las buenas costumbres y el orden público, comprometiéndose en todos los casos a
                            no causar daños al MADES ni a ningún tercero. A tal efecto, el Usuario se abstendrá de utilizar
                            cualquiera de los servicios con fines o efectos ilícitos, prohibidos en los presentes Términos y
                            Condiciones, lesivos de los derechos e intereses de terceros, o que de cualquier forma puedan dañar,
                            inutilizar, sobrecargar, deteriorar o impedir la normal utilización de los servicios, documentos y toda
                            clase de contenidos en cualquier equipo informático del MADES, o de otros Usuarios.
                        </p>

                        <p>Toda la información que los usuarios faciliten en el Portal (caso de ser solicitada) deberá ser veraz,
                            garantizando su autenticidad. El usuario será el único responsable de las manifestaciones falsas o
                            inexactas que realice y de los perjuicios que cause a este sitio o a terceros, como consecuencia de
                            ésta.
                        </p>

                        <h3>RESPONSABILIDADES DEL MADES</h3>
                        <p>
                            El MADES no será, en ningún caso, responsable de reclamos efectuados por el mal uso que se realicen
                            de las funcionalidades disponibles en la Web, por los usuarios, ni de las infracciones que afecten a los
                            derechos de terceros, incluyendo entre otras las disposiciones en materia de protección de datos
                            personales, protección de los consumidores y usuarios, derechos de propiedad industrial o
                            intelectual, derecho de autor, información confidencial, contenidos nocivos e ilícitos o competencia
                            desleal, vigentes en cada momento y/o cualquier otro derecho que pudiera resultar afectado.
                        </p>

                        <p>
                            El MADES no será responsable por el incumplimiento por parte de las instituciones cuyos sitios se
                            encuentran enlazados en el Portal, de cualquier ley, normativa o reglamento que sea aplicable. No
                            responderá reclamos de terceros, aun cuando estas fueren relacionadas a su presencia en el visor
                            web.
                        </p>

                        <p>
                            El MADES no asumirá responsabilidad alguna en caso de imposibilidad de brindar el servicio del visor
                            web de manera continua, debido a cualquier tipo de falla o interrupción originada por casos fortuitos,
                            causas de fuerza mayor, o circunstancias fuera del control del MADES, incluyendo, pero sin limitarse a
                            cuestiones relativas a conectividad, dominios, etc.
                        </p>

                        <p>
                            En ningún caso el MADES será responsable por daño emergente, lucro cesante y/o cualquier otra
                            pérdida que pudieran sufrir el usuario, como consecuencia directa o indirecta de la prestación del
                            servicio del Portal, tratándose y limitándose a ser una herramienta de facilitación y accesibilidad a los
                            sitios de las mismas.
                        </p>

                        <h3>PROTECCIÓN DE DATOS PERSONALES</h3>
                        <h3>CÓMO SE RECOPILAN Y UTILIZAN LOS DATOS PERSONALES</h3>

                        <p>
                            Por datos personales se entiende cualquier dato que, por sí solo o junto con otros datos, pueda
                            utilizarse para identificar a una persona física. El Usuario proporciona directamente dichos datos
                            cuando utiliza el visor web o interactúa con nosotros, por ejemplo, contactando en el Portal para
                            obtener asistencia.
                        </p>

                        <p>
                            También es posible obtener datos registrando cómo interactúa con nuestro sitio web. Por ejemplo, se
                            puede utilizar tecnologías como cookies o recibir datos de uso del software que se ejecuta en su
                            dispositivo.<br/><br/>
                        Los datos personales que recopilamos incluyen:<br/><br/>
                            - Nombres y Apellidos,<br/>
                            - Correo electrónico.
                        </p>

                        <p>
                            Además, se recopila la información que nos proporciona el Usuario y el contenido de los mensajes
                            que envía, como la información de consulta que proporciona o las preguntas o información que
                            proporciona para el servicio de atención.
                        </p>

                        <p>
                            Datos sobre el dispositivo: es posible recoger datos específicos sobre su dispositivo como, por
                            ejemplo, el modelo de equipo, la versión del sistema operativo, los identificadores únicos y los datos
                            sobre la red móvil.
                        </p>

                        <p>
                            Datos de registro: El MADES podrá obtener y almacenar automáticamente determinada información
                            en sus registros cada vez que el Usuario utilice los servicios a su disposición o consulte contenidos.
                            Dicha información incluye:<br/><br/>
                            - Información detallada sobre la utilización de los servicios provistos,<br/>
                            - La dirección IP;<br/>
                            - Cookies, datos que permitirán identificar el navegador o cuenta del usuario.
                        </p>

                        <h3>UTILIZACIÓN DE DATOS PERSONALES</h3>
                        <p>
                            El MADES puede utilizar sus datos personales para los siguientes propósitos:<br/><br/>
                            - Optimizar los servicios brindados por el Gobierno Nacional.<br/>
                            - Obtener métricas de uso y niveles de servicio del Gobierno Nacional.<br/>
                            - Proporcionarle información sobre los servicios o trámites en línea.<br/>
                            - Permitirle acceder a servicios gubernamentales y realizar transacciones.<br/>
                            - Proporcionarle contenido y experiencia de usuario personalizados.<br/>
                            - Solución de problemas cuando se envía informes de errores.<br/>
                            - Resguardar la seguridad de nuestros productos y servicios, ejecutando y mejorando nuestros programas de seguridad.
                        </p>

                        <p>
                            El MADES también puede recopilar y utilizar información como el número de visitas a su sitio web,
                            con el fin de comprender cómo los usuarios utilizan nuestros sitios web, productos y servicios para
                            que se pueda mejorar nuestros servicios y satisfacer mejor sus necesidades.
                        </p>

                        <p>
                            Los datos que recopilamos pueden compartirse con otros departamentos gubernamentales, agencias
                            y organismos públicos para los mismos fines.
                            <h3>EL MADES NO:</h3>
                            - Vende o alquila sus datos a terceros.<br/>
                            - Comparte sus datos con terceros con fines de marketing.<br/>
                            <h3>El MADES SI:</h3>
                            - Compartirá sus datos para cumplir con las leyes aplicables o responder a procedimientos
                            legales válidos, por ejemplo, por orden judicial o para prevenir fraudes u otros delitos.
                        </p>

                        <p>
                            La única instancia en la que el Usuario suministra información al visor web es por medio del
                            formulario de contacto, la misma no es almacenada y es utilizada para la generación de un correo
                            electrónico de respuesta.
                        </p>
                        <p>
                            El MADES podrá utilizar cookies cuando el usuario navega por el sitio web, pero éste puede configurar
                            su navegador para ser avisado de la recepción de las cookies e impedir en caso de considerarlo
                            adecuado su instalación en el disco duro.
                        </p>

                        <h3>HIPERENLACES</h3>
                        <p>
                            Los hiperenlaces contenidos en el sitio web pueden dirigir a páginas web de terceros o sitios
                            vinculados. El MADES, no asume ninguna responsabilidad por el contenido, información o servicios
                            que pudieran aparecer en dichos sitios, que tendrán exclusivamente carácter informativo y que en
                            ningún caso implican relación alguna entre el MADES y las personas o entidades titulares de los
                            contenidos o de los sitios web donde tal información se encuentre. En este sentido, el contenido de
                            tales enlaces será únicamente responsabilidad de las entidades respectivas.
                        </p>

                        <h3>MODIFICACIONES A LAS CONDICIONES DE USO</h3>
                        <p>
                            El MADES se reservará el derecho de modificar las Políticas de Privacidad y de Tratamiento de Datos
                            Personales aquí contenidas a su libre elección y en cualquier momento, cualquier cambio será
                            informado y publicado oportunamente en la página web. El Usuario se compromete a revisar
                            periódicamente esta sección para estar informado de tales modificaciones y cada nuevo acceso del
                            usuario a la página será considerado una aceptación tácita de las nuevas condiciones.
                        </p>
                    </Grid>
                </Container>
            </div>
            <Sidebar open={true} drawerWidth={drawerWidth} />
        </Fragment>
    );
}

export default (PrivacyPolicies);
