import './App.css';
import 'leaflet/dist/leaflet.css';
import { createTheme, ThemeProvider, StyledEngineProvider, adaptV4Theme } from '@mui/material/styles';
import { blueGrey, red, teal } from "@mui/material/colors";
import {
    BrowserRouter as Router,
    Route,
    Switch
} from "react-router-dom";
import EmptyLayout from "./layouts/EmptyLayout";
import Home from "./pages/Home";
import Vulnerabilidades from "./pages/Vulnerabilidades";
import ManualUsuario from "./pages/ManualUsuario";
import NotFound from "./pages/NotFound";
import GlobalSpinnerContextProvider from "./context/GlobalSpinnerContext";
import GlobalSpinner from "./components/GlobalSpinner/GlobalSpinner";
import Contacto from "./pages/Contacto";
import PrivacyPolicies from "./pages/PrivacyPolicies";

const theme = createTheme(adaptV4Theme({
    typography: {
        fontSize: 12,
        fontFamily: [
            'Open-Sans',
            'Rubik',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif'
        ].join(','),
    },
    palette: {
        primary: teal,
        secondary: blueGrey,
        error: red,
        // Used by `getContrastText()` to maximize the contrast between the background and
        // the text.
        contrastThreshold: 3,
        // Used to shift a color's luminance by approximately
        // two indexes within its tonal palette.
        // E.g., shift from Red 500 to Red 300 or Red 700.
        tonalOffset: 0.2
    }
}));

// const NotFound = () => {
//     return <div>NotFound</div>;
// };

const EmptyRoute = ({ component: Component, ...rest }) => {
    return (
        <Route
            {...rest}
            render={matchProps => (
                <EmptyLayout>
                    <Component {...matchProps} />
                </EmptyLayout>
            )}
        />
    );
};


function App() {
  return (
      <GlobalSpinnerContextProvider>
          <StyledEngineProvider injectFirst>
              <ThemeProvider theme={theme}>
                  <GlobalSpinner />
                  <Router>
                      <Switch>
                          <Route exact path="/" component={Home} />
                          <Route exact path="/vulnerabilidades" component={Vulnerabilidades} />
                          <Route exact path="/manual_usuario" component={ManualUsuario} />
                          <Route exact path="/contacto" component={Contacto} />
                          <Route exact path="/privacy-policy" component={PrivacyPolicies} />
                          <EmptyRoute component={NotFound} />
                      </Switch>
                  </Router>
              </ThemeProvider>
          </StyledEngineProvider>
      </GlobalSpinnerContextProvider>
  );
}

export default App;
