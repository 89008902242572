import React, { Fragment, useEffect, useRef } from 'react';
import { MapContainer, LayersControl, TileLayer, ZoomControl, GeoJSON } from "react-leaflet";
import Header from "../components/Header/Header";
import Sidebar from "../components/Sidebar/Sidebar";
import makeStyles from '@mui/styles/makeStyles';
import { control, DomUtil } from "leaflet";
import { SimpleMapScreenshoter } from 'leaflet-simple-map-screenshoter';
import chroma from 'chroma-js';
import { API_URL } from "../utils/config";
import { useGlobalSpinnerActionsContext } from "../context/GlobalSpinnerContext";

const drawerWidth = 120;

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
    },
    content: {
        flexGrow: 1,
        marginLeft: theme.spacing(9),
        padding: theme.spacing(3),
        top: theme.spacing(7),
        width: `calc(100% - ${drawerWidth}px)`,
        position: "fixed",
        overflowX: "hidden",
        height: `calc(100vh - 104px)`
    },
    contentShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(["width", "margin"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen
        })
    },

}));

// Temperatura
// Absoluto
const colorsTemperatura = [
    // '#940808', '#420505', /* 46 */
    // '#D90D08', /* 40 */
    '#830a0a', /* 40 */
    '#E31A1C', '#E06408', '#E0B408', /* 34 36 38 */
    '#FCEDAE',/* 30 */
    '#E0E808', '#7BD408', '#3AAA08', /* 24 26 28 */
    '#088D3D' /* 21 */,
    '#08A950', '#08D678', /* 16 18 */
    '#08EACC' /* 14 */
    // '#08E4EF',
];
// const celsius = [
//     12, 14, 16, 18, 21, 24, 26, 28, 30, 34, 36, 38, 40, 43, 46
// ];
const celsius = [
    40, 38, 36, 34, 30, 28, 26, 24, 21, 18, 16, 14
];
const paletteTemp = chroma.scale(colorsTemperatura).domain(celsius);

// Anomalias
const anomaliaCelsius = [
    7, 6, 5, 4, 3, 2, 1, 0, -1, -2, -3, -4, -5, -6, -7
]
const rangeAnomaliaCelsius = ['#93003a', '#b2343e', '#cd5a40', '#e4813e', '#f4a939', '#fcd32c', '#ffffff',
    '#b9e6ea', '#92c5d9', '#73a3ca', '#5682bb', '#3761ac', '#00429d']
const paletteAnomaliaTemp = chroma.scale(rangeAnomaliaCelsius).domain(anomaliaCelsius);

// Función para set color
function getColorTemp(d, isAnomalia) {
    if (isAnomalia) {
        return paletteAnomaliaTemp(d).hex();
    }
    return paletteTemp(d).hex();
}

// Precipitación
// Porcentaje
const precipitacionPorcentaje = [
    -50, -33, -17, 0, 17, 33, 50
];
const precipitacion = [
    0, 1700, 2500, 3300, 4100
];
const palettePrecip = chroma.scale('YlGnBu').domain(precipitacion);
const palettePrecipPorcentaje = chroma.scale('RdYlBu').domain(precipitacionPorcentaje);
// Set color precipitación
function getColorPrecip(d, isHistorico) {
    if (isHistorico) {
        return palettePrecip(d).hex();
    }
    return palettePrecipPorcentaje(d).hex();
}

const initialBounds = [
    [-27.5918335654071001, -62.6446174358194980],
    [-19.2876472226754991, -54.2589239095134985]
];

var logosApoyo;
function Legend({ map, layer }) {
    useEffect(() => {
        if (map) {
            const legend = control({ position: "bottomright" });
            legend.onAdd = () => {
                const div = DomUtil.create("div", "info legend");
                let title = '';
                let labels = [];
                let from;
                let to;
                let originData;
                let isAnomalia;

                if (layer.includes('PREC')) {
                    let isHistorico = false;
                    title = "Precipitación mm/año (%)";
                    if (layer.includes('HIS')) {
                        isHistorico = true;
                        from = precipitacion[0];
                        originData = precipitacion;
                        title = "Precipitación (mm/año)";
                    } else {
                        from = precipitacionPorcentaje[precipitacionPorcentaje.length];
                        originData = precipitacionPorcentaje;
                    }
                    // labels.push(
                    //     '<i style="background:' + getColorPrecip(from, isHistorico) + '"></i> ' +
                    //     from
                    // );
                    for (let i = originData.length; i > 0; i=i-1) {
                        from = originData[i - 1];

                        labels.push(
                            '<i style="background:' + getColorPrecip(from, isHistorico) + '"></i> ' +
                            from
                            // from + (to ? "&ndash;" + to : "+")
                        );
                    }
                } else {
                    title = "Temperatura (ºC)"
                    if (layer.includes('ANO')) {
                        isAnomalia = true;
                        from = anomaliaCelsius[0];
                        originData = anomaliaCelsius;
                    } else {
                        isAnomalia = false;
                        from = celsius[0];
                        originData = celsius;
                    }
                    labels.push(
                        '<i style="background:' + getColorTemp(from, isAnomalia) + '"></i> ' +
                        from
                    );
                    for (let i = 0; i < originData.length - 1; i=i+1) {
                        from = originData[i + 1];

                        labels.push(
                            '<i style="background:' + getColorTemp(from, isAnomalia) + '"></i> ' +
                            from
                            // from + (to ? "&ndash;" + to : "+")
                        );
                    }
                }

                div.innerHTML =
                     `<h4>${title}</h4>`
                div.innerHTML += labels.join("<br>");
                return div;
            };

            logosApoyo = control({ position: "bottomright" });
            logosApoyo.onAdd = () => {
                const helpDiv = DomUtil.create("div", 'info legend');
                helpDiv.innerHTML = '' +
                    '<span><Typography><b>Con el apoyo de: </b></Typography></span>' +
                    '<img src="GEF.png" alt="Logo" style="height: 50px" />' +
                    '<img src="PNUD.png" alt="Logo" style="height: 55px" />';

                return helpDiv;
            }
            map.addControl(logosApoyo);
            map.addControl(legend);

            return () => {
                map.removeControl(legend);
                map.removeControl(logosApoyo);
            };
        }
    }, [map, layer]);
    return null;
}

const downloadJson = async ({data}) => {
    const fileName = "file";
    const json = JSON.stringify(data);
    const blob = new Blob([json],{type:'application/json'});
    const href = await URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = href;
    link.download = fileName + ".geojson";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
}

var downloadFile;

function MapDownload({data, map}) {
    const params = data || 0;

    useEffect(() => {
        if (map) {
            downloadFile = control({ position: "topright" });
            downloadFile.onAdd = () => {
                const helpDiv = DomUtil.create("div", 'leaflet-control-simpleMapScreenshoter');
                helpDiv.innerHTML = '<button title="Descargar GeoJSON" ' +
                    'style="width: 30px; height: 30px; border: none; background: white">' +
                    '<i class="fa fa-download"></i>' +
                    '</button>';

                helpDiv.addEventListener("click", () => {
                    downloadJson({data: params});
                });
                return helpDiv;
            }
            map.addControl(downloadFile);

            return () => {
                map.removeControl(downloadFile);
            };
        }

    }, [map, params]);
    return null;
}

function MapDownloadImage({map}) {

    useEffect(() => {
        if (map) {
            let pluginOptions = {
                preventDownload: false, // prevent download on button click
                domtoimageOptions: {}, // see options for dom-to-image
                position: 'topright', // position of take screen icon
                screenName: 'Mapa - Escenarios Climáticos', // string or function
                hideElementsWithSelectors: ['.leaflet-control-container'], // by default hide map controls All els must be child of _map._container
                mimeType: 'image/png', // used if format == image,
                caption: null, // string or function, added caption to bottom of screen
                captionFontSize: 15,
                captionFont: 'Arial',
                captionColor: 'black',
                captionBgColor: 'white',
                captionOffset: 5,
                // callback for manually edit map if have warn: "May be map size very big on that zoom level, we have error"
                // and screenshot not created
                onPixelDataFail: async function({ node, plugin, error, mapPane, domtoimageOptions }) {
                    // Solutions:
                    // decrease size of map
                    // or decrease zoom level
                    // or remove elements with big distanses
                    // and after that return image in Promise - plugin._getPixelDataOfNormalMap
                    return plugin._getPixelDataOfNormalMap(domtoimageOptions)
                }
            }

            new SimpleMapScreenshoter(pluginOptions).addTo(map);

            return () => { };
        }

    }, [map]);
    return null;
}

const Home = props => {
    const classes = useStyles();
    const setGlobalSpinner = useGlobalSpinnerActionsContext();

    const [dpto, setDpto] = React.useState(null);
    const [unidadHidrografica, setUnidadHidrografica] = React.useState(null);
    const [parametro, setParametro] = React.useState(null);
    const [map, setMap] = React.useState(null);
    const dptoRef = useRef(null);
    const unidadHidrograficaRef = useRef(null);
    const parametroRef = useRef(null);
    const [parametroLayerName, setParametroLayerName] = React.useState("DPTOTP2MABSRCP452040")

    useEffect(() => {
        setGlobalSpinner(true);
        fetch(`${API_URL}mapa_base/departamento/geo/`)
            .then(res => res.json())
            .then(
                (dptoResult) => {
                    setDpto(dptoResult);
                    fetch(`${API_URL}escenario_climatico/layer/${parametroLayerName}/`)
                        .then(res => res.json())
                        .then(
                            (result) => {
                                setGlobalSpinner(false);
                                filterParametroLayer(result);
                            },
                            (error) => {
                                setGlobalSpinner(false);
                                console.error('error al traer dpto', error)
                                alert('Ocurrió un error al obtener mapa parametrizado')
                            }
                        )
                },
                (error) => {
                    setGlobalSpinner(false);
                    console.error('error al traer dpto', error);
                    alert('Ocurrió un error al obtener mapa de Departamento');
                }
            );

    }, [])

    const styleOptions = { color: 'black', fillOpacity: 0, weight: 2}

    const filterParametroLayer = (data) => {
        try {
            let parametroLayer = parametroRef.current;
            parametroLayer.clearLayers();
            parametroLayer.addData(data, {});
            setParametro(data);
        } catch (e) {
            // No hacer nada
        }

    }

    const filterMapaBaseLayer = (data, unidadTerritorio, ids) => {
        let dptoLayer = dptoRef.current
        let unidadHidrograficaLayer = unidadHidrograficaRef.current
        dptoLayer.clearLayers();
        unidadHidrograficaLayer.clearLayers();
        if (unidadTerritorio === 'departamento') {
            dptoLayer.addData(data);
            setDpto(data);
        } else {
            unidadHidrograficaLayer.addData(data);
            setUnidadHidrografica(data);
        }
        let bounds;
        if (ids && ids.length > 0) {
            bounds = getBoundingBox(data);
            map.flyToBounds([bounds.corner1, bounds.corner2]);
        } else {
            map.flyToBounds(initialBounds);
        }

    }

    const getBoundingBox = (data) => {
        let bounds = {}, bbox;

        // Loop through each "feature"
        let minX = 0, minY = 0, maxX = -100, maxY = -100
        for (let i = 0; i < data.features.length; i++) {
            bbox = data.features[i].bbox;
            minX = bbox[0] < minX ? bbox[0] : minX;
            minY = bbox[1] < minY ? bbox[1] : minY;
            maxX = bbox[2] > maxX ? bbox[2] : maxX;
            maxY = bbox[3] > maxY ? bbox[3] : maxY;
        }
        bounds.corner1 = [minY, minX];
        bounds.corner2 = [maxY, maxX];

        // Returns an object that contains the bounds of this GeoJSON data.
        // The keys describe a box formed by the northwest (xMin, yMin) and southeast (xMax, yMax) coordinates.
        return bounds;
    }

    function style(feature) {
        let valor = feature.properties.valor;
        let fillColor;
        if (parametroLayerName.includes('PREC')) {
            if (parametroLayerName.includes('HIS')) {
                fillColor = getColorPrecip(valor, true);
            } else {
                fillColor = getColorPrecip(valor, false);
            }
        } else {
            if (parametroLayerName.includes('ANO')) {
                fillColor = getColorTemp(valor, true);
            } else {
                fillColor = getColorTemp(valor, false);
            }
        }
        return {
            fillColor: fillColor,
            weight: 2,
            opacity: 1,
            color: 'white',
            dashArray: '3',
            stroke: 0,
            fillOpacity: 0.7
        };
    }

    const handleCallback = (data) => {
        let ids = data.ids.map(d => d.id);
        let layerName = data.layerName.toUpperCase();
        setParametroLayerName(layerName);
        setGlobalSpinner(true);
        fetch(`${API_URL}mapa_base/${data.unidadTerritorio}/geo/?ids=${ids}`)
            .then(res => res.json())
            .then(
                (mapa_base) => {
                    fetch(`${API_URL}escenario_climatico/layer/${layerName}/?base=${data.unidadTerritorio}&ids=${ids}`)
                        .then(res => res.json())
                        .then(
                            (parametro) => {
                                setGlobalSpinner(false);
                                filterMapaBaseLayer(mapa_base, data.unidadTerritorio, ids);
                                filterParametroLayer(parametro);
                            },
                            (error) => {
                                setGlobalSpinner(false);
                                console.error('error al traer dpto', error)
                                alert('Ocurrió un error al obtener mapa de Departamento')
                            }
                        )
                },
                (error) => {
                    setGlobalSpinner(false);
                    console.error(error);
                    alert('Ocurrió un error al obtener mapa de Departamento')
                }
            )
    }

    function onEachFeatureUH(feature, layer) {
        layer.bindTooltip(feature.properties.nombre,
            {permanent: true, direction: "center", className: "my-labels"}
        )
    }

    function onEachFeature(feature, layer) {
        let valor = feature.properties.valor;
        layer.on({
            'mouseover': function (e) {
                if (feature.properties && valor) {
                    valor = Number(Math.round(valor+'e2')+'e-2');
                    layer.bindTooltip(`<p><b>Valor:</b> ${valor}</p>`).openTooltip();
                }},
            'mouseout': function (e) {
                layer.closeTooltip();
            }
        })
    }

    return (
        <Fragment>
            <div className={classes.root}>
                <Header/>
                <MapContainer
                              whenCreated={setMap}
                              className={`${classes.content}`}
                              center={[-23.442503, -58.4438324]}
                              zoom={6}
                              zoomControl={false}
                              scrollWheelZoom={true}>
                    <LayersControl position="topright">
                        <LayersControl.BaseLayer checked name="OSM">
                            <TileLayer
                                attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                            />
                        </LayersControl.BaseLayer>
                        <LayersControl.BaseLayer name="Esri World Imagery">
                            <TileLayer
                                attribution='Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community'
                                url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
                            />
                        </LayersControl.BaseLayer>
                    </LayersControl>
                    <ZoomControl position="topright" />
                    <MapDownload data={parametro} map={map} />
                    { dpto ?
                        <GeoJSON ref={dptoRef} pathOptions={styleOptions} data={dpto} /> : null}
                        <GeoJSON ref={unidadHidrograficaRef} pathOptions={styleOptions}
                                 data={unidadHidrografica} onEachFeature={onEachFeatureUH} />
                    <GeoJSON ref={parametroRef} style={style} onEachFeature={onEachFeature} data={parametro} />
                    <Legend map={map} layer={parametroLayerName} />
                    <MapDownloadImage map={map} />
                </MapContainer>
            </div>
            <Sidebar open={true} drawerWidth={drawerWidth} parentCallback={handleCallback} />
        </Fragment>
    )
}

export default (Home);
